.updates-container {
    width: 220px;
    padding: 20px;
    background-color: #2b2b2b;
    border-radius: 12px;
    color: #b8d9c4;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.updates-title {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
}

.updates-horizontal-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates four columns of equal width */
    position: relative;
}

.updates-horizontal-item {
    padding: 5px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.updates-horizontal-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: -10px; /* Adjust based on your gap and visual preference */
    top: 0;
    bottom: 0;
    width: 1px;
    // background-image: linear-gradient(#b8d9c4, #b8d9c4);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1px 100%;
    border-right: 2px dashed #4a4a4a; /* Adjust color and style as needed */
}

.updates-label {
    font-size: 12px;
    color: #d5f3e9;
    margin-bottom: 8px;
}

.updates-value {
    font-size: 20px;
    color: #b8d9c4;
    font-weight: bold;
}
