.current-status-main{
    display: flex;
    align-items: center;
    gap: 5px;
}

.current-state-color-container {
    height: 6px;
    width: 6px;
    border-radius: 50%;
}

.current-status-icon {
    margin-left: 5px;
}