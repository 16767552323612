//ArmoryTestedModal
.armorytested_main {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #0c1d32;
  border-left: #398ffe solid 6px;
  height: 7.625rem;
  margin: 2.5rem 1.5rem;

  .armorytested_icon {
    margin: 0rem 3rem;
  }

  .armorytested_content {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    height: 90%;
    width: 75%;

    .armorytested_title {
      font-size: 1.5rem;
      font-weight: 700;
      color: #398ffe;
    }

    .armorytested_text {
      font-weight: 400;
      font-size: 1.125rem;
      color: white;
      opacity: 0.8;
    }
  }
}

//OffenseCard
.offensecard_main {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: #ffffff;
  background-color: #272728;
  height: 37rem;
  width: 31%;
  gap: 33px;
  gap: -77px;
  padding: 1rem 1.5rem;

  .offensecard_title {
    font-size: 1.25rem;
    height: 2rem;
    text-align: center;
    width: 100%;

    span {
      font-weight: 700;
    }
  }

  .dotted-line-image {
    border-top: 2px dotted #ffffff;
    width: 100%;
    margin: 25px 0px;
  }

  .dotted-line {
    border-top: 2px dotted #ffffff;
    width: 100%;
    margin: 0;
  }

  .image-card {
    text-align: center;
    width: 100%;
  }

  .offensecard_image_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
  }

  .offensecard_image {
    width: 10rem;
    height: auto;
  }

  .coming-soon-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    margin-top: 15px;
  }

  .initiate-attack-button {
    background: transparent;
    border: solid 1px #398ffe;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
    }

    .button-text {
      color: #398ffe;
      margin-left: 10px;
    }

    .play-icon {
      fill: #398ffe;
      height: 1.5em;
      width: 1.5em;
    }

    &:before {
      content: "";
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      background: linear-gradient(to right,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0));
      z-index: -1;
      border-radius: 4px;
    }
  }

  .initiate-attack-button-disable {
    background: transparent;
    border: solid 1px #767272;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
    }

    .button-text {
      color: #767272;
      margin-left: 10px;
    }

    .play-icon {
      fill: #767272;
      height: 1.5em;
      width: 1.5em;
    }

    &:before {
      content: "";
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      background: linear-gradient(to right,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0));
      z-index: -1;
      border-radius: 4px;
    }
  }

  .offensecard_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .offensecard_output_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #636363;
    width: 100%;
    border-radius: 5px;
    margin: 0 5px;

    .offensecard_output_title {
      font-size: 16px;
      font-weight: 700;
      padding: 0.5rem 0.8rem;
    }

    .margindiv {
      background-color: #130c15;
      width: 100%;
      padding: 0.3rem 0.3rem;
      border-top: 1px solid #636363;
    }

    .card_subsection {
      background-color: #120b15;
      width: 100%;
      height: 100%;
      max-height: 9.6rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 9px;
      }

      &::-webkit-scrollbar-track {
        background: #120b15;
      }

      &::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
      }

      ul {
        list-style: none;
      }
    }
  }

  .offensecard_chart {
    width: 35%;
    height: 50%;
    padding: 1px;
    margin-top: 0;

    .chart_top_value {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 25px;
      padding-bottom: 4px;
      font-size: 11px;
    }

    .chart_bottom_value {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 25px;
      padding-top: 4px;
      font-size: 11px;
    }

    canvas {
      width: 100%;
      height: 100%;
    }
  }

  .offensecard_results {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 12rem;

    .offensecard_results_title {
      font-weight: 700;
    }

    .offensecard_success {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      width: 100%;
      color: #ff4a4a;
      height: 40px;
      display: flex;
      align-items: center;
    }

    .offensecard_results_content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .offensecard_results_text {
        width: 65%;
        font-size: 16px;
      }

      .offensecard_failed {
        font-weight: 700;
        color: #3ddc97;
        font-size: 16px;
        margin-top: 1rem;
        margin-bottom: 0.3rem;
      }
    }
  }

  .image-card{
    .offensecard_output_section {
      margin-top: 40px;
      margin-bottom: 30px;
      .card_subsection {
        max-height: 9.6rem;
      }
    }
  }
}

.doughnut-chart-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0px;

  canvas {
    height: 100px;
  }

  .chart-and-legend {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .chart-legend-width {
      width: 114px;
    }

    .chart-legend-set {
      padding-left: 0px;
      // padding-left: 30px;
      // width: 100%;
    }
  }
}

.bar-graph {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: transparent;
  width: 100%; // Full width of the container
  height: 3rem; // Height of the graph container
  box-sizing: border-box;
  border-bottom: 2px solid #ffffff;

  .bar-container {
    display: flex;
    height: 100%;
    width: 50%;
    align-items: flex-end;
  }

  .bar-container-1 {
    display: flex;
    height: 100%;
    width: 50%;
    align-items: flex-end;
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
  }

  .bar2 {
    flex: 1;
    margin: 0 0.7rem;
    display: flex;
    align-items: flex-end;
    position: relative;

    .bar-value {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      color: white;
      font-weight: bold;
      padding: 2px 5px;
    }
  }

  .bar1 {
    flex: 1;
    margin: 0 0.7rem;
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -3.01rem;
    transform: scaleY(-1);

    .bar-value {
      position: absolute;
      bottom: -3px;
      transform: translateY(86%) !important;
      color: white;
      font-weight: bold;
      padding: 2px 7px;
    }
  }
}

.greentext {
  color: var(--status-success);
}

.redtext {
  color: var(--status-danger);
}

.disable-link-color {
  color: var(--disable-link);
  padding: 8px;
}

.cursor-none {
  cursor: auto;
}

.comming-text-tool {
  color: #0a0a0e;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
}

.comming-tool-body {
  border-radius: 5px;
  padding: 6px 12px;
  border-radius: 5px;
  background: #ffffff;
  width: fit-content;
}

.changed-tool-body {
  border-radius: 5px;
  padding: 6px 12px;
  border-radius: 5px;
  background: #ffffff;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 0px;
  max-width: 500px;
  width: 500px;

  ::-webkit-scrollbar {
    width: 3px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #514f4f !important;
    border-radius: 20px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #514f4f !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }

  ::-webkit-scrollbar-button {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  scrollbar-width: thin !important;
  scrollbar-color: #514f4f transparent !important;
}

.changed-custom-popover-arrow {
  max-width: 500px;
  word-wrap: break-word;
  white-space: normal;
  width: 500px;

  .popover-arrow::after {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }
}

.email-tool-body {
  border-radius: 5px;
  padding: 6px 12px;
  border-radius: 5px;
  background: #ffffff;
  width: max-content;
}

#popover-basic-comming {
  .popover-arrow::after {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }
}

.custom-popover-arrow {
  .popover-arrow::after {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }
}

@keyframes ledLightEffect {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.animation-status {
  animation: ledLightEffect 1.5s infinite;
}

.form-floating>label {
  z-index: 1 !important;
}

.expired {
  color: #FF6155;
  cursor: pointer;
  text-decoration: underline;
}

