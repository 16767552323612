


.custom-container {
  background-image: url(../../../../images/tree-layre.png); /* Set the background color to match your image */
  padding: 20px; /* Or any other value to align the tree properly */
  background-size: cover;
}






.custom-container {
  .dropdown {
    .dropdown-menu {
      --bs-dropdown-min-width: 9.5rem !important;
    }
    .btn-check:checked + .btn,
    :not(.btn-check) + .btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn:hover,
    .btn.show {
      color: white !important;
    }
  }
}

.node {
  cursor: pointer;

  circle {
    fill: black;
    stroke: #ffffff;
    stroke-width: 1.5px;
  }

  text {
    font-size: 18px;
    font-family: "Kodchasan";
    background-color: black;
    fill: #ffffff;
  }
}

path {
  &.link {
    fill: #ffffff;
    stroke: #939393;
    stroke-width: 1.5px;
    stroke-dasharray: 3;
  }

  &.grey {
    fill: none;
    stroke: grey;
    stroke-width: 1.5px;
  }
}

.map-tree-flow{
  border: 1px solid #636363;
  background: #242222;
}

