/* -------------------------------------------------------------------------------
## Global
------------------------------------------------------------------------------- */

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-content {
  background: linear-gradient(117.41deg,
      rgba(31, 56, 84, 0.5) 0%,
      rgba(13, 21, 30, 0.5) 100%),
    linear-gradient(0deg, #2f3741, #2f3741);
}

.sticky-bar {
  z-index: 2 !important;
  top: 80px;
}

.logo-img {
  width: 199px;
  height: 50px;
}

.removing-state {
  svg {
    fill: var(--bs-danger);
    stroke-width: 4px;
  }
}

.loading-state {
  svg {
    fill: var(--bs-btn-color);
    stroke-width: 4px;
  }
}

.btn-full {
  padding: 12px 14px;
  width: 100%;
}

.card {
  .btn-full {
    width: 100%;
  }
}

.btn-primary {
  --bs-btn-color: #fff;
}

.text-custom {
  color: var(--white) !important;

  &:hover {
    color: var(--blue) !important;
  }
}

.input-with-icon {
  .append-icon {
    position: absolute;
    left: 15px;
    top: 11px;
    font-size: 18px;
    z-index: 6;
  }

  .form-control {
    padding-left: 40px;

    &.form-control-llg {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.otp {
  width: 45px !important;
  height: 48px !important;
  padding: 14px !important;
}

.react-tel-input {
  .form-control {
    background: $input-bg !important;
    width: 100% !important;
    border-radius: $input-border-radius !important;
    border-color: $input-border-color !important;
    color: $input-color !important;
  }
}

.overlap-h {
  top: 30px;
  z-index: 2;
  width: calc(100% - 30px);
  margin-bottom: -30px;
}

.overlap-box {
  width: calc(100% - 30px);
  top: -65px;
  // margin-bottom: 75px;
  backdrop-filter: blur(28px);
}

.overlap-info-box {
  top: -250px;
  margin-bottom: -250px;
  width: 100%;

  // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.24),
  //   0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  // backdrop-filter: blur(28px);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.table-custom {
  th {
    color: var(--bs-body-color);
    font-weight: normal;
  }

  > :not(caption)>*>* {
    background-color: transparent;
  }
}

// Share Button
.share-btn {
  margin: 8px;
}

.news-item {
  .news-image {
    max-height: 35vh;
    object-fit: cover;
  }

  // News
  .news-details {
    font-size: 1.25rem;
  }
}

.news-dock {
  position: fixed;
  z-index: 4;
  top: 50%;
  left: calc(100% - 50px);

  .icon {
    stroke: var(--bs-body-color);
    fill: var(--bs-btn-color);
    width: 25px;
  }
}

// News Main Crousel
.main-news-carousel {
  min-height: 70vh;

  .carousel-indicators {
    position: relative;
    margin-right: 0;
    margin-left: 0;
  }

  .news-image {
    max-height: 50vh;
    object-fit: cover;
    width: 100vw;
  }
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.main-section::-webkit-scrollbar-track {
  background-color: transparent;
}

.main-section::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
}

.main-section::-webkit-scrollbar:hover {
  width: 12px;
}

.main-section::-webkit-scrollbar {
  width: 5px;
}

.children-section {
  padding-right: 15px;
}

// Media Queries
@media only screen and (max-width: 821px) {
  .main-section {
    background: url(../../images/left-nav-bg.svg);
  }

  .custom-val {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 50vw;
  }

  .news-details {
    font-size: 1.15rem;
  }
}

@media only screen and (min-width: 821px) {
  .main-section {
    height: calc(-60px + 100vh);
    overflow-y: auto;
  }

  .def {
    width: 100px;
  }

  .portal {
    margin: 90px 40px !important;
  }

  .frame {}

  .bg {
    padding: 40px;
    // padding: 10px;
    background: $secondary-bg;
  }
}

.vh-75 {
  height: 75vh;
}

.vh-65 {
  height: 65vh;
}

.main_container_style {
  padding: 2.5rem 2rem;
}

.w-fit-content {
  width: fit-content;
}

.main-layout {
  // height: 520px;
}

// Terminal
#terminalEditor {
  // overflow-y: auto;
  // display: flex;
  // flex-direction: column-reverse;
  height: 60vh;

  @media only screen and (max-width: 820px) {
    height: 50vh;
  }
}

.change-color {
  animation-name: change-color;
}

@keyframes change-color {
  from {
    color: #ed7e07 !important;
  }

  to {
    color: inherit;
  }
}

.hide-toggle {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3DDC97;
  /* Adjust the text color as needed */
  padding: 10px;
  border-radius: 5px 0 0 5px;
  z-index: 1000;
  /* Ensure it stays on top */
  height: 329px;
  width: 50px;
  box-shadow: 5px 11px 19.1px 0px #68686857;
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
  cursor: pointer;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.hide-toggle span {
  transform: rotate(-90deg);
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;

}

.chevrons-right {
  position: absolute;
  top: 68px;
}

.bounce-enter-active {
  animation: fadeout 2s forwards;
  transition: opacity 3s ease-in-out;
}

.bounce-leave-active {
  animation: fadeIn 2s forwards;
  transition: opacity 3s ease-in-out;
}

@keyframes fadeout {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}


.hide-toggle {
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.hide-toggle:hover {
  opacity: 0.8;
}

/* Invitations.css */
.scroll-container {
  height: 700px;
  overflow: auto;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.sbc {
  width: 10px !important;
}

.hide-invalid-icon-field {
  .form-control.is-invalid {
    background-image: none !important;
  }
}

.terms-modal .modal-dialog {
  width: 600px;
  max-width: 100%;
}

.password-requirement .dot {
  height: 4px;
  /* adjust the size as needed */
  width: 4px;
  /* adjust the size as needed */
  background-color: gray;
  /* default color */
  border-radius: 50%;
  display: inline-block;
}

.react-tel-input .flag.bg {
  display: none !important;
}
.sidebar-dp-height{
  height: 50px,
}
.card-title-container{
  margin-bottom: 1rem;
}
.issues-container-card{
  .security-highlight-card{
    height: 100% !important;
  }
}
.generic-card{
  height: 100%;
}
.assets-container{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.risk-score-container{
  display: flex;
  align-items: center;
}
.elipses-container{
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.risk-p-r{
  padding-right: 0.5rem !important;
}
.countent-cve-large{
  display: block;
}
.countent-cve-small{
  display: none;
}
.w-97{
  width: 97%;
}
.assets-column-container{
  display: flex;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.ricks-options{
  display: flex;
}
.cve-popup-container{
  display: flex;
}
.cvs-epss-popup-section{
  display: flex;
}

.risk-score-popup-container{
display: block;
  margin-right: 37px;
  align-items: center;
}
.disabled-user-view{
    opacity: 0.5;
    border: 1px solid #686868 !important;
    pointer-events: none; 
}
