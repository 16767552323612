/* -------------------------------------------------------------------------------
### Imports -> Variables -> Colors
------------------------------------------------------------------------------- */
@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/variables-dark";
//We have to import the functions first to use map.merge()


// We have to import the variables beforehand to use the variable $grid-breakpoints.
// Otherwise, compiler will show error - '$grid-breakpoints not defined.'

// @import '~bootstrap/scss/variables';


$main: #017DF9;

// Dark Varaiables
$dark-bg: #100E10;
$dark-bg-secondary: #16273b;
$bodyColor: #919eab;
$primary: $main;
$secondary: $dark-bg-secondary;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $bodyColor;

//override bootstrap
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "dark": $bodyColor,
);






$new-breakpoints: (
    xl: 1280px,
    xxl:1600px
);

$grid-breakpoints: map-merge($grid-breakpoints, $new-breakpoints);

$new-container-max-widths: (
  xl: 1220px,
  xxl:1520px
);

$container-max-widths: map-merge($container-max-widths, $new-container-max-widths);

