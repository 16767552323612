.badge-container {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 4px;
    padding: 2px 8px;
    .badge-text {
        font-size: 10px;
        font-weight: 400;
        text-wrap: nowrap;
        overflow: hidden;
    }
}

.sub-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    .content-link{
        justify-content: space-between;
        align-items: center;
    }
}

.justify-between {
    justify-content: space-between;
}

.visit-link {
    font-size: 14px;
    font-weight: 600;
    line-height: 15.6px;
    color: var(--link-color);
    text-decoration: none;
    cursor: pointer;
    margin-top: 2px;
    align-self: start;
  }
