 /*!
 * 
/**********************************************************************************************************************************************
*
*  //.......................................................................................................................................
*  //........AAAAAAAA........RRRRRRRRRRRRRRRRR....MMMMMMMMM......MMMMMMMM.......OOOOOOOOOOO........RRRRRRRRRRRRRRRRR..RRYYYYY.......YYYYYY..
*  //........AAAAAAAA........RRRRRRRRRRRRRRRRRR...MMMMMMMMM......MMMMMMMM......OOOOOOOOOOOOOO......RRRRRRRRRRRRRRRRRR.RRYYYYY.......YYYYYY..
*  //.......AAAAAAAAA........RRRRRRRRRRRRRRRRRR...MMMMMMMMM......MMMMMMMM.....OOOOOOOOOOOOOOOO.....RRRRRRRRRRRRRRRRRR..RYYYYYY.....YYYYYYY..
*  //.......AAAAAAAAAA.......RRRRRRRRRRRRRRRRRRR..MMMMMMMMMM....MMMMMMMMM....OOOOOOOOOOOOOOOOOO....RRRRRRRRRRRRRRRRRRR.RYYYYYY....YYYYYYY...
*  //.......AAAAAAAAAA.......RRRRRR.....RRRRRRRR..MMMMMMMMMM....MMMMMMMMM...OOOOOOOOOOOOOOOOOOOO...RRRRRR.....RRRRRRRR..YYYYYYY...YYYYYY....
*  //......AAAAAAAAAAA.......RRRRRR.......RRRRRR..MMMMMMMMMM....MMMMMMMMM...OOOOOOO......OOOOOOO...RRRRRR.......RRRRRR...YYYYYY..YYYYYYY....
*  //......AAAAAAAAAAAA......RRRRRR.......RRRRRR..MMMMMMMMMM...MMMMMMMMMM..OOOOOOO........OOOOOOO..RRRRRR.......RRRRRR...YYYYYYY.YYYYYY.....
*  //.....AAAAAA.AAAAAA......RRRRRR.......RRRRRR..MMMMMMMMMMM..MMMMMMMMMM..OOOOOOO........OOOOOOO..RRRRRR.......RRRRRR....YYYYYYYYYYYYY.....
*  //.....AAAAAA.AAAAAA......RRRRRR.....RRRRRRRR..MMMMMMMMMMM..MMMMMMMMMM..OOOOOO..........OOOOOO..RRRRRR.....RRRRRRRR.....YYYYYYYYYYY......
*  //.....AAAAAA..AAAAAA.....RRRRRRRRRRRRRRRRRRR..MMMMMMMMMMM..MMMMMMMMMM..OOOOOO..........OOOOOO..RRRRRRRRRRRRRRRRRRR.....YYYYYYYYYY.......
*  //....AAAAAA...AAAAAA.....RRRRRRRRRRRRRRRRRR...MMMMMMMMMMMMMMMMMMMMMMM..OOOOOO..........OOOOOO..RRRRRRRRRRRRRRRRRR.......YYYYYYYYY.......
*  //....AAAAAA...AAAAAAA....RRRRRRRRRRRRRRRRR....MMMMMM.MMMMMMMMMMMMMMMM..OOOOOO..........OOOOOO..RRRRRRRRRRRRRRRRR........YYYYYYYY........
*  //....AAAAAA....AAAAAA....RRRRRRRRRRRRRRR......MMMMMM.MMMMMMMMMMMMMMMM..OOOOOO..........OOOOOO..RRRRRRRRRRRRRRR...........YYYYYY.........
*  //...AAAAAAAAAAAAAAAAA....RRRRRR.RRRRRRRRR.....MMMMMM.MMMMMMMMMMMMMMMM..OOOOOO..........OOOOOO..RRRRRR.RRRRRRRRR..........YYYYYY.........
*  //...AAAAAAAAAAAAAAAAAA...RRRRRR...RRRRRRR.....MMMMMM.MMMMMMMMM.MMMMMM..OOOOOOO........OOOOOOO..RRRRRR...RRRRRRR..........YYYYYY.........
*  //...AAAAAAAAAAAAAAAAAA...RRRRRR....RRRRRRR....MMMMMM..MMMMMMMM..MMMMM..OOOOOOO........OOOOOOO..RRRRRR....RRRRRRR.........YYYYYY.........
*  //..AAAAAAAAAAAAAAAAAAA...RRRRRR....RRRRRRRR...MMMMMM..MMMMMMMM..MMMMM...OOOOOOO......OOOOOOO...RRRRRR....RRRRRRRR........YYYYYY.........
*  //..AAAAAA.......AAAAAAA..RRRRRR.....RRRRRRR...MMMMMM..MMMMMMMM..MMMMM...OOOOOOOOOOOOOOOOOOOO...RRRRRR.....RRRRRRR........YYYYYY.........
*  //.AAAAAA.........AAAAAA..RRRRRR......RRRRRRR..MMMMMM..MMMMMMMM..MMMMM....OOOOOOOOOOOOOOOOOO....RRRRRR......RRRRRRR.......YYYYYY.........
*  //.AAAAAA.........AAAAAA..RRRRRR......RRRRRRRR.MMMMMM...MMMMMM...MMMMM.....OOOOOOOOOOOOOOOO.....RRRRRR......RRRRRRRR......YYYYYY.........
*  //.AAAAAA.........AAAAAAA.RRRRRR.......RRRRRRR.MMMMMM...MMMMMM...MMMMM......OOOOOOOOOOOOOO......RRRRRR.......RRRRRRR......YYYYYY.........
*  //.AAAAA...........AAAAAA.RRRRRR........RRRRRRRMMMMMM...MMMMMM...MMMMM........OOOOOOOOOO........RRRRRR........RRRRRRR.....YYYYYY.........
*  //.......................................................................................................................................
*
********************************************************************************************************************************************* 
 * Armory v1.0.0 (https://app.armory.com/)
 * Copyright 2023 ARMORY BY Cisotronix
 * Description: Variables, responsible to define global variable.
 * 
 * Author: 
 * URL: 
 * 
 * 
 */


/* -------------------------------------------------------------------------------
# Globals
------------------------------------------------------------------------------- */
@import "__globals/index";

/* -------------------------------------------------------------------------------
# Imports
------------------------------------------------------------------------------- */
@import "__imports/imports";

/* -------------------------------------------------------------------------------
# Bootstrap
------------------------------------------------------------------------------- */
@import "__bootstrap/bootstrap";
// @import "__bootstrap/bootstrap_lite";
@import "~bootstrap/scss/bootstrap.scss";

/* -------------------------------------------------------------------------------
# Core
------------------------------------------------------------------------------- */
@import "_1_core/core";

/* -------------------------------------------------------------------------------
# Fonts
------------------------------------------------------------------------------- */
@import "_2_fonts/fonts";

/* -------------------------------------------------------------------------------
# Third Party
------------------------------------------------------------------------------- */
@import "_3_third_party/third_party";

/* -------------------------------------------------------------------------------
# Components
------------------------------------------------------------------------------- */
@import "_4_components/components";

/* -------------------------------------------------------------------------------
# Utilities
------------------------------------------------------------------------------- */
@import "_5_utilities/utilities";

/* -------------------------------------------------------------------------------
# Pages
------------------------------------------------------------------------------- */
@import "_6_pages/pages";
@import "_6_pages/_3_easm/_1_base";
@import "_6_pages/_5_waf/_1_base";
@import "_6_pages/_6_cyber_services/_1_base";
@import "_6_pages/_7_cves-issues/_1_base";
@import "_6_pages/_7_cves-issues/2_responsive";
@import "_6_pages/_8_certificates/_1_base";
@import "_6_pages/_9_asset_discovery/_1_base";
@import "_6_pages/_10_admin-dashboard/_1_base";

  
