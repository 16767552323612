.fs-12 {
  font-size: 12px;
}

.rounded-l-0 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.rounded-r-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.list_header {
  .custom-table {
    background-color: #0a0a0e00;

    thead {
      tr {
        background-color: #0a0a0e00;
        border-radius: 5px !important;

        th {
          border: none !important;
        }

        td {
          padding: 13px !important;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 13px !important;

          &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
  }

  .search-container {
    width: 300px;
  }
}

.btn_rounded {
  border-radius: 6px !important;
}

.badge-close {
  position: relative;
  top: -3px;
  color: slategray;

  &:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    color: slategray;

    border-color: transparent !important;
  }
}

.discovery-nav-text {
  font-size: 1.2rem;
}

.discovery-active-nav-text {
  border-bottom: 3px solid #398ffe;
  font-size: 1.2rem !important;
  font-weight: bolder !important;
  color: #398ffe !important;
  margin-bottom: -8px;
}

.domain-sections-container {
  border-radius: 5.4px;
  // background: black;
  // padding: 0px;
  border: 1px solid #636363;
}

.assets-discovery-table {
  .sub-table-container {
    overflow: auto;
    margin-bottom: 20px;

    .custom-table {
      // width: 1850px !important;
    }

    .table-responsive {
      position: relative;
    }

    .table-responsive::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .table-responsive::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 10px;
    }

    .table-responsive::-webkit-scrollbar:hover {
      width: 2px;
    }

    .table-responsive::-webkit-scrollbar {
      height: 5px;
    }
  }
}

.header {
  background: #3e3e3e;
  border-bottom: 1px solid #636363;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 45px;
}

.cursor-pointer {
  cursor: pointer;
}

.header-title {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.form-control:focus {
  box-shadow: none;
  background: none;
}

.scan-button {
  width: 250px;
  background: var(--link-color);
}

.active-text {
  color: #3ddc97;
  font-size: 16px;
  font-weight: 400;
}

.badge-border {
  border: 1px solid #80ed994d;
  background: rgb(62 62 62) !important;
  border-radius: 5px;
  color: #3ddc97;
  padding-top: 0px;
  padding-bottom: 0px;
}

.add-button {
  font-size: 16px;
  margin-left: 10px;
  color: #95c4ff;
}

.target-default {
  padding: 2px 12px 0px;
  color: white;
  border-radius: 5px;
  background: #398ffe;
  font-size: 10px;
  font-weight: 200;
  position: relative;
  left: 6px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// target dropdown css

.dropdown-item:hover {
  background-color: #4a4a4a !important;
}

.target-hover {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-icon {
  display: none;
}

.target-hover:hover .edit-icon {
  display: block;
}

// delete button
.delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    margin-right: 10px;
    color: #398ffe;
  }
}

.target-modal {
  background: #252525;
  border: 2px solid #93c2ff !important;
  width: 650px;

  button {
    background: #252525;
    width: 20%;
    color: #398ffe;
    border: 2px solid #398ffe !important;
  }
}

.scan-stats-card {
  color: #fff;
  border: none;
  margin-top: 20px;
  background: linear-gradient(
    4.67deg,
    rgba(90, 90, 91, 0) -25.21%,
    #5a5a5b 130.89%
  );
  border: 1px solid #636363;
  // height: 76px;

  .row {
    padding: 10px 0;

    .stat-col {
      border-right: none;

      &:not(:last-child) {
        border-right: 1px solid #ddd;
      }
    }
  }

  .card-title {
    font-size: 0.9rem;
    color: #aaa;
  }

  .card-text {
    font-size: 1.5rem;
    color: #ddd;

    .stats-change,
    .stats-remove {
      font-size: 0.7rem;
      margin-left: 5px;
    }
  }

  .stats-value {
    color: #bcf4de;
    font-size: 20px;
    font-weight: 700;
  }
}

#popover-basic-container {
  max-width: 800px;

  .nav-link.active {
    background: #4a4a4a;
    color: #ececec;
  }
}

.custom-action-popover {
  right: 0px;
  top: -13px;
  position: absolute;
  background: #0e0e0e;
  width: 225px;
  padding: 10px 7px;
  border: 1px solid rgb(57, 143, 254);
  cursor: pointer;
}

.custom-action-items {
  padding: 8px 12px;
  margin-bottom: 5px;
  color: #f5f5f5;
  font-size: 16px;
}
.custom-action-items:hover {
  background: #4a4a4a;
  border-radius: 2px;
}

.scrollable-checkbox-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-checkbox-list::-webkit-scrollbar {
  width: 8px;
}

.scrollable-checkbox-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollable-checkbox-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// privacy policy
.scrollable-checkbox-list-privacy {
  max-height: 500px;
  overflow-y: auto;
}

.scrollable-checkbox-list-privacy::-webkit-scrollbar {
  width: 8px;
}

.scrollable-checkbox-list-privacy::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollable-checkbox-list-privacy::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.underline {
  text-decoration: underline;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

.pr-2 {
  padding-right: 10px;
}

.content-start {
  align-content: start;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.tab-content-container {
  background: #2e2e2e;
}

//Change Password

.text-4 {
  font-size: 16px;
}

.password-modal {
  width: 650px;

  .btn:disabled {
    background: #767272;
  }
}

.reset-form {
  margin: auto;

  .reset-input {
    width: 70%;
    margin: auto;
  }

  .form-control {
    margin: 5px 0px 0px 0px;
    height: 50px !important;
    background: #1b1b1c !important;
    border-radius: 6px !important;
    border: 2px solid white;
  }

  .form-control:not(.is-invalid) {
    border-color: white !important;
  }

  .form-control::placeholder {
    color: #6f6f6f !important;
    font-size: 16px;
  }
}

.ml-5 {
  margin-left: 20px;
}

.base-checkbox {
  .form-check-label {
    font-size: 12px;
    font-weight: 400;
    color: #ececec;
  }
}

.tab-pane-title {
  font-size: 13px;
  font-weight: 400;
  color: white;
}

.filter-badge {
  background: #636363 !important;
  border-radius: 5px;
  color: #ffffff;
  padding-top: 2px;
  padding-bottom: 0px;
}

.export-dp {
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #272728;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dp-active-menu {
    border: 1px solid #3785ec;
    box-shadow: 0px 5px 30px 0px #15172c80;
    padding: 0;
    margin-top: 10px;
  }

  // .show{
  //   border: 1px solid #3785EC !important;

  // }
}

.scan-button {
  background-color: #398ffe !important;
}

.scan-button:disabled {
  background-color: #767272 !important;
  border-color: #767272 !important;
  color: white !important;
}

.selected-filter-box {
  min-width: 170px;
}

.error-msg {
  width: 100%;
  font-size: 16px;
  color: var(--bs-form-invalid-color);
}

.terms-cond {
  display: flex;
  align-items: baseline;
}

.font-18 {
  font-size: 18px;
}

.rounded-4 {
  border-radius: 4px !important;
}

.link-color {
  background: var(--link-color) !important;
}

.empty-scan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  padding: 20px;
}

.globe-plus-position {
  position: absolute;
  top: 59%;
  left: 66%;
  transform: translate(-50%, -50%);
}

// assets inventory animation styling start
.animation-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.gradient {
  position: absolute;
  z-index: -1;
  background: linear-gradient(90deg, #0d99ff 0%, #0d99ff 100%);
  filter: blur(100px);
  height: 350px;
  width: 350px;
  border-radius: 50%;
  animation: glow 2s infinite alternate;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.logo-animation {
  width: 30%;
  height: 483px;
  overflow: hidden;
  position: relative;
}

.video-container {
  width: 30%;
  height: 483px;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logs-output {
  height: 483px;
  width: 40%;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.section-main {
  height: 484px;
  width: 33%;
  position: relative;
  overflow: hidden;
  z-index: 1;

  .section_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url("../../../../images/assetsStats.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 3px;
    height: 80%;
    width: 80%;
    z-index: 1;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    .content_2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }

    .content_1 {
      display: flex;
      align-items: center;
      justify-content: center;

      .content_1_value {
        color: #64ffff;
        font-weight: 700;
        font-size: 1.4rem;
      }
    }
  }

  .stats-heading-text {
    font-size: 10px;
    font-weight: 400;
    color: #f5f5f5;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-cursor {
  font-weight: 100;
  font-size: 24px;
  color: #3ddc97;
  animation: blink 1s step-start infinite;
}

// assets inventory animation styling end

.scanner-text-container {
  border: 1px solid #767272;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  span {
    color: #767272;
    font-size: 16px;
    font-weight: 700;
  }
}

.color-grey-dim {
  color: var(--disbaled-bg-color);
}
.stop-text {
  font-size: 16px;
  font-weight: 700;
  color: #398ffe;
}
.sort-dp {
  width: 310px;
  .dropdown-toggle {
    background: transparent;
    border: 1px solid #ffffff;
    color: #a7a7b1;
  }
  .dropdown-menu {
    margin-top: 4px;
  }
  .dropdown-toggle:hover,
  .dropdown-toggle:active,
  .dropdown-toggle.show {
    color: #a7a7b1 !important;
    background-color: transparent !important; /* or specify your desired color */
    border-color: inherit; /* or specify your desired color */
  }
}
.breadcrumb-list {
  a {
    cursor: default;
  }
}

// summary asset
.data-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-info {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.data-value {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
}

.data-label {
  font-size: 16px;
  font-weight: 400;
  color: #e5e5e5;
  opacity: 0.9;
}

.data-active {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.9;
}
.summary-icon-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.summary-horizontal-item {
  padding: 5px 35px 5px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.summary-horizontal-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: -5px;
  margin-bottom: 25px;
}
.summary-horizontal-item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1px 100%;
  border-right: 2px dashed #4a4a4a;
}

.web-interface-container {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px 8px 2px 8px;
  border-radius: 4px;
  width: max-content;
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
}

.advance-filter-row {
  width: 900px;
  height: 402px;
  border: 2px solid #398ffe;
  border-radius: 4px;
  .advanced-navlinks {
    display: flex;
    align-items: center;
    .active-circle-filter {
      background: #398ffe;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 7px;
    }
  }
  .advance-col {
    overflow: auto;
    height: 400px;
  }
  .advance-col::-webkit-scrollbar {
    width: 5px;
  }

  .advance-col::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  .advance-col::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 8px;
  }
}

#popover-basic-container {
  .advanced-navlinks {
    font-size: 14px;
    font-weight: 400;
    color: #ececec !important;
    padding: 11px 18px 10px;
  }
  .advanced-navlinks.active {
    font-weight: 700 !important;
    background: #2e2e2e !important;
  }
}

.advance-filter-content {
  padding: 0px 12px 0px 35px;
  .search-container {
    height: 30px !important;
    padding: 16px 12px !important;
    margin-bottom: 16px;
    input {
      color: white !important;
    }
  }
  .new-rule-text {
    color: white;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    svg {
      width: 19px !important;
      stroke: white;
      margin-right: 6px;
    }
  }
  .condition-container {
    background: #424242;
    padding: 16px 16px 0px 16px;
    border-radius: 4px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    .and-operator-container {
      margin-top: 14px;
      position: relative;
      span {
        position: absolute;
        left: 6px;
        font-size: 12px;
        color: #64ffff;
      }
    }
    .rule-row {
      display: flex;
      align-items: flex-end;
      .condition-select {
        width: 35%;
        padding-right: 10px;
        span {
          color: #ffffffcc;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 4px;
          display: inline-block;
        }
        select {
          border: 1px solid white;
          border-radius: 5px;
          color: white;
          background-color: transparent;
          cursor: pointer;
        }
      }
      .dropdown {
        position: static;
      }
      .condition-select-action {
        position: relative;
        top: -9px;
        display: flex;
        align-items: center;
        width: 10%;
        justify-content: space-between;
        svg {
          // margin-left: 12px;
          width: 20px;
          height: 19px;
          cursor: pointer;
        }
        svg:hover {
          path {
            stroke: #398ffe;
            fill: #398ffe;
          }
        }
        .delete-icon {
          visibility: hidden;
          width: 17px !important;
          height: 19px !important;
        }
      }
    }
    .rule-row:hover {
      .delete-icon {
        visibility: visible;
        width: 17px !important;
        height: 19px !important;
      }
    }
    .dropdown {
      background: #424242;
      button {
        background: #424242;
        border: 1px solid white;
        border-radius: 5px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
      }
      button.show {
        border: 2px solid #398ffe;
        img {
          transform: rotate(180deg);
        }
      }
      .not-selected {
        color: #6f6f6f;
      }
      .dropdown-menu {
        width: 200px;
        max-height: 200px;
        overflow: auto;
        background-color: #100e10;
        overflow-x: hidden;
        .dropdown-item {
          color: white;
          background: transparent;
          width: 92%;
          margin: auto;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 400;
        }
        .dropdown-item:hover {
          color: white;
          background: #4a4a4a;
        }
        .selected-option {
          color: white;
          background: #4a4a4a;
        }
        .custom-value {
          border-bottom: 1px solid #ffffffcc;
          display: flex;
          align-items: center;
          margin-bottom: 3px;
          span {
            font-size: 24px;
            font-weight: 400;
            display: block;
            margin: 0px;
            height: 10px;
            position: relative;
            top: -16px;
            margin-right: 10px;
          }
        }
        .custom-value:hover {
          color: #398ffe;
          background: transparent !important;
          span {
            color: #398ffe;
          }
        }
        .hide-border {
          border: none;
        }
        .hide-border:hover {
          background-color: transparent;
        }
        .input-custom {
          border-bottom: 1px solid #ffffff;
          width: 92%;
          margin: auto;
          padding-bottom: 4px;
          display: flex;
          align-items: center;
          position: relative;
          input {
            border-radius: 2px;
            width: 100%;
            margin: 0px;
            background: #4a4a4a;
            border: 1px solid #4a4a4a;
            color: white;
            font-weight: 400;
            font-size: 14px;
          }
          input::placeholder {
            color: #7f7f7f;
          }
          input:focus {
            border: 1px solid #4a4a4a;
          }
          svg {
            position: absolute;
            right: 8px;
            position: absolute;
            right: 8px;
            width: 20px;
            height: 20px;
            padding: 3px;
          }
        }
      }
      .dropdown-menu::-webkit-scrollbar {
        width: 5px;
      }

      .dropdown-menu::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 8px;
      }

      .dropdown-menu::-webkit-scrollbar-thumb {
        background-color: white;
        border-radius: 8px;
      }
    }
  }
  .filter-save-container {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      color: #398ffe;
      cursor: pointer;
      font-size: 13px;
    }
  }
  .select-advance-filter {
    margin-top: 20px;
    .select-all-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid white;
      padding-bottom: 10px;
      .checkbox-container {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: white;
        input {
          margin-right: 10px;
          width: 16px;
          height: 16px;
          padding-top: 0px;
          margin-top: 1px;
          background-color: transparent;
        }
        input:checked {
          background-color: #0d6efd;
        }
      }
      .clear-text {
        font-size: 13px;
        color: white;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .select-advance-filter-options {
    .select-all-container {
      margin-top: 13px;
      display: flex;
      align-items: center;
      .checkbox-container {
        display: flex;
        align-items: flex-start;
        font-size: 13px;
        color: white;
        input {
          margin-right: 10px;
          width: 16px;
          height: 16px;
          padding-top: 0px;
          margin-top: 1px;
          background-color: transparent;
        }
        input:checked {
          background-color: #0d6efd;
        }
        .condition-contains {
          max-width: 500px;
          display: flex;
          align-items: flex-start;
          .multi-condition-box {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 13px;
            .condition-box {
              display: flex;
              align-items: center;
              margin-bottom: 7px;
              svg {
                margin: 0px 6px;
                width: 20px;
                path {
                  stroke: #64ffff;
                  fill: #64ffff;
                }
              }
            }
          }
          .cross-icon {
            width: 20px;
            cursor: pointer;
            height: 18px;
          }
          .cross-icon:hover {
            path {
              stroke: #398ffe;
              fill: #398ffe;
            }
          }
        }
      }
      .clear-text {
        font-size: 13px;
        color: white;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
.custom--dropdown-container {
  text-align: left;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.custom--dropdown-container .dropdown-input {
  padding: 7px 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: 7px;
}

.custom--dropdown-container
  .dropdown-input
  .dropdown-selected-value.placeholder {
  color: #82868b;
}

.custom--dropdown-container .dropdown-tool svg {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.custom--dropdown-container .dropdown-tool svg.translate {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.custom--dropdown-container .dropdown-menu {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 5px;
  position: absolute;
  -webkit-transform: translateY(6px);
  -ms-transform: translateY(6px);
  transform: translateY(6px);
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  overflow: auto;
  background-color: #fff;
  z-index: 99;
  max-height: 312px;
  min-height: 50px;
}

.custom--dropdown-container .dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

.custom--dropdown-container .dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom--dropdown-container .dropdown-menu::-webkit-scrollbar-thumb {
  background: #888;
}

.custom--dropdown-container .dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom--dropdown-container .dropdown-menu.alignment--left {
  left: 0;
}

.custom--dropdown-container .dropdown-menu.alignment--right {
  right: 0;
}

.custom--dropdown-container .dropdown-item {
  padding: 7px 10px;
  cursor: pointer;
  -webkit-transition: background-color 0.35s ease;
  transition: background-color 0.35s ease;
  border-radius: 6px;
  font-weight: 500;
}

.custom--dropdown-container .dropdown-item:hover {
  background-color: rgba(130, 134, 139, 0.05);
  color: #ff7300;
}

.custom--dropdown-container .dropdown-item.selected {
  background-color: rgba(255, 115, 0, 0.075);
  color: #ff7300;
  font-weight: 600;
}

.custom--dropdown-container .search-box {
  padding: 5px;
}

.custom--dropdown-container .search-box input {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom--dropdown-container .dropdown-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px;
}

.custom--dropdown-container .dropdown-tag-item {
  background-color: #ff7300;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 4px;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.custom--dropdown-container .dropdown-tag-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
}
.light-theme-tooltip {
  .tooltip-arrow::before {
    border-top-color: white !important;
  }
  .tooltip-inner {
    background: white;
    color: black;
    font-weight: 600;
    max-width: fit-content;
  }
}

.regular-tab-pane {
  padding: 4px 12px 0px 45px;
  .search-container-input {
    margin-bottom: 20px;
    .search-container {
      height: 36px !important;
      input {
        color: white;
        border: none;
      }
    }
  }
  .tab-pane-selector {
    border-bottom: 1px solid white;
    padding-bottom: 11px;
    margin-bottom: 15px !important;
  }
  .base-checkbox {
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
    .form-check-label {
      font-size: 13px !important;
      color: white !important;
      margin-left: 10px !important;
      text-decoration: none !important;
      font-weight: 400 !important;
      word-break: break-all;
    }
    input {
      width: 16px;
      height: 16px;
      padding-top: 0px;
      margin-top: -1px;
      background-color: transparent;
    }
    input:checked {
      background-color: #0d6efd;
    }
  }
}
.filter-dot-container {
  position: relative;
  .filter-dot {
    position: absolute;
    background: #398ffe;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 8px;
    right: 8px;
  }
}
.new-filter-chips {
  margin: 4px 0px 7px;
  .new-filter-box {
    background: rgba(255, 255, 255, 0.2) !important;
    color: white;
    width: max-content;
    margin-right: 12px;
    margin-bottom: 12px;
    .main-categorize-filter-box {
      // text-transform: capitalize;
      display: flex;
      align-items: center;
    }
    .categorize-filter-box {
      display: flex;
      align-items: center;
    }
  }
}
.light-theme-filter-tooltip {
  .tooltip-arrow::before {
    border-top-color: white !important;
  }
  .tooltip-inner {
    background: white;
    color: black;
    font-weight: 600;
    max-width: 450px !important;
  }
}
.badge-container-chip {
  padding: 0px 0px 0px 24px;
  margin: 6px 10px 6px 6px;
  li {
    margin-bottom: 5px;
    text-align: left;
  }
}
.condition-circle-icon {
  path {
    fill: #64ffff;
  }
}
.service-categiry-chip {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px 8px;
  border-radius: 4px;
}

.unread {
  font-weight: bold;
}

.unread-dot {
  height: 8px;
  width: 8px;
  background: #398ffe;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.notifications-container {
  max-height: 482px;
  overflow-y: auto;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-color: #ddd;
}

.notification-icon {
  margin-right: 10px;
}

.notification-description {
  font-size: 14px;
  margin: 0;
  line-height: 18px;
  font-weight: 600;
}

.notification-date-time {
  font-size: 10px;
  line-height: 13px;
  color: #9a9595;
  margin-left: 39px;
  margin-bottom: 0;
}

.notification-link {
  color: #ffffff;
  font-weight: 600;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
}

.notification-badge {
  position: absolute;
  top: 17px;
  right: 22px;
}
.badge-action {
  width: 14px;
  height: 14px;
  font-size: 8px;
  line-height: 22px;
  font-weight: 500;
}

#popover-bell.popover {
  --bs-popover-max-width: 382px;
  background: #373737;
  border: 2px solid #398ffe;
  margin-top: -5px;
  .popover-body {
    padding: 0px;
  }
  .notification-action {
    padding: 12px;
  }
}

#popover-bell.bs-popover-bottom > .popover-arrow::after,
#popover-bell.bs-popover-bottom > .popover-arrow::before {
  border-width: 0 !important;
}

.navbar .icon-pointer.active {
  path {
    fill: #398ffe !important;
  }
}
.no-notifications {
  text-align: center;
  margin: 51px;
}
.notifications-empty {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  color: white;
}

.notifications-text {
  font-size: 12px;
  font-weight: 400;
  color: #cdcdcd;
  padding: 0px 10px;
}
.notifications-container {
  .scrollable-checkbox-list {
    max-height: max-content;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.date-picker-container {
  button {
    margin: 0px 10px !important;
    background-color: transparent !important;
  }
}

.scanimg {
  height: 574px;
  img {
    object-fit: cover;
  }
}

.custom-add-button {
  color: white;
  padding: 9px 12px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #4a90e2;
  margin-left: 20px;
}

.custom-add-button svg {
  margin-right: 8px;
}

.custom-add-button:hover {
  border-color: #3498db;
}
.left-filter {
  width: 84%;
}
.add-new-asset {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
}
.add-new-asset:hover {
  border-color: #0d6efd;
}
.export-asset-button {
  button {
    margin-left: -6px !important;
  }
}
.scrollable-issue-side-popup{
  overflow-y: auto;
}

.scrollable-issue-side-popup::-webkit-scrollbar {
  width: 8px;
}

.scrollable-issue-side-popup::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollable-issue-side-popup::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.new-filter-chips-edit {
  margin: 4px 0px 7px;
  .new-filter-box {
    color: white;
    width: max-content;
    margin-right: 12px;
    margin-bottom: 12px;
    .main-categorize-filter-box {
      display: flex;
      align-items: center;
    }
    .categorize-filter-box {
      display: flex;
      align-items: center;
    }
  }
}

