/* -------------------------------------------------------------------------------
#### Components -> Header -> Common Base
------------------------------------------------------------------------------- */

/* TopBarHeader.css */
.nav-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 15px !important;
  background: #192029;
  box-shadow: 4px -3px 17px 0px #00000080;
}

.loginLogos{
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px 10px;
    border-top: 1px solid #d3d3d354;
    margin-top: 20px; 
    svg {
      width: 120px;
      height: 56px;
    }
}

.nav-logo-img {
  width: 90px;
  height: 39.9px;
  top: 10px;
  left: 19px;
  gap: 0px;
}

.multiLogos{
  display: flex;
  margin-right: 0px;
  align-items: center;
}

.logoDevider{
  width: 1px;
    background: white;
    height: 17px;
    margin-left: 15px;
    margin-right: 7px;
}

.newCompanyLogo{
  width: 70px;
height: 35px;
}

.navbar-brand,
.nav-link,
.dropdown-toggle {
  font-family: Kodchasan;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: white;
}

.nav-dropdown .dropdown-item {
  font-family: 'Kodchasan', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: black;
}

.arrow-icon {
  fill: rgb(173, 171, 171) !important;
}

#tools-nav-dropdown::after {
  display: none;
}

#dashboard-nav-dropdown::after {
  display: none;
}

.dropdown-toggle::after {
  display: none;
}


.navbar .icon-pointer {
  fill: white !important;
  margin-bottom: 10px;
  cursor: pointer;
}

.user-email {
  overflow-wrap: break-word;
  word-break: break-all;
  padding: 0px 6px 5px;
  text-align: center;
}

.user-icon {
  margin-bottom: 10px;
  path {
    fill: white;
    transition: fill 0.3s;
  }
}

.user-icon.open path {
  fill: #398ffe !important;
}

.user-icon:hover path,
.user-icon:focus path,
.icon-pointer:hover path,
.icon-pointer:focus path {
  fill: #398ffe !important;
}

.arrow-icon {
  transition: color 0.3s;
  color: white;
}

.arrow-icon.active {
  color: #398ffe;
}

.base-drop-down-arrow {
  path {
    fill: #fff !important;
  }
}

.base-drop-down-arrow {
  transition: transform 0.3s ease-in-out;
  transform: rotate(-90deg);

}

.base-drop-down-arrow.open {
  transform: rotate(0);
}

.reject-drop-down-arrow {
  path {
    fill: #fff !important;
  }
}

.reject-drop-down-arrow {
  transition: transform 0.3s ease-in-out;
  transform: rotate(0);
}

.reject-drop-down-arrow.open {
  transform: rotate(-180deg);
}

.clear-all-text {
  font-size: 16px;
  color: #398FFE;
  font-weight: 600;
  text-wrap: nowrap;
  cursor: pointer;
}
.disable-text {
  font-size: 16px;
  color: #929292;;
  font-weight: 600;
  text-wrap: nowrap;
  cursor: default;
}

.dropdown-menu.show {
  display: block;
  border: 2px solid rgba(57, 143, 254, 1);
}

.modal-close-white {
  color: white;
  font-size: 18px;
  font-weight: 700;
  background: transparent !important;
  width: 50px !important;
}

.modal-text {
  color: white;
}

.icon-dropdown {
  width: 40px;
  height: 50px;
  // border-bottom: 3px solid #398ffe;
    margin-bottom: -9px;
  .user-dropdown {
    width: 90%;
    border-bottom: 2px solid #535356;
    margin-bottom: 20px;
  }

  .dropdown-menu.show {
    margin-left: -220px;
    width: 255px;
    display: flex;
    margin-top: -6px;
    border: 2px solid #3E90FF;
    flex-direction: column;
    background: #0A0A0E;
    align-items: center;
  }

  .logout-dropdown {
    border-top: 2px solid #535356;
    margin-top: 20px;

  }

  .text-dark {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 90%;
    background: #0E0E0E;
    height: 45px;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .nav-main {
    display: none;
  }
}

.nav-link-active {
  border-bottom: 3px solid #398ffe;
  font-size: 16px !important;
  font-weight: bolder !important;
  color: #398FFE !important;
  margin-bottom: -8px;
}

.icon-main {
  width: 40px;
  height: 50px;
  margin-bottom: -9px;
}

.icon-main.icon-active {
  border-bottom: 3px solid #398ffe;
}

.icon-active .icon-pointer path {
  fill: #398ffe !important;
}

.tools {
  &.tools-active {
    border-bottom: 3px solid #398ffe;
    margin-bottom: -9px;
  }

  .nav-dropdown-active {
    color: #398FFE !important;
    font-weight: bolder !important;
    font-size: 16px !important;

    path {
      fill: #398FFE !important;
    }
  }
}

.nav-dropdown-active {
  color: #398FFE !important;
  font-weight: 700;
}









// active link styling
// border-bottom: 2px solid #398ffe;
// font-size: 16px;
// font-weight: 700;
// color: #398FFE !important;
// margin-bottom: -8px;