/* Adjust styles for exactly 1440x900 or close dimensions */
@media screen and (max-width: 1440px) and (max-height: 900px) {
  /* Typography Adjustments */
  .sidebar-top-heading {
    font-size: 14px;
  }

  .breadcrumb-item.active {
    font-size: 18px;
  }

  .status-text,
  h2 {
    font-size: 14px;
  }

  .dp-selected-item .text-truncate,
  .discovery-nav-text {
    font-size: 16px !important;
  }

  .data-value,
  .data-active {
    font-size: 16px;
    font-weight: 400;
  }
  .card-title {
    color: #f5f5f5;
  }
  .sidebar-dp-height {
    height: 37px;
    width: 97% !important;
    margin-left: 4px !important;
  }

  /* Accordion Styles */
  .custom-accordion-sub .custom-accordion-body {
    height: 29px !important;
  }

  .custom-accordion-sub .warning-icon {
    position: absolute;
    right: 30px;
    top: 5px;
    background: #3b3b3e;
    border-radius: 4px;
    padding: 2px;
    width: 18px;
    height: 18px;
  }

  .custom-accordion .sub-body {
    width: 100%;
  }

  .custom-accordion .accordion-button .accordion-arrow {
    width: 22px;
    height: 16px;
  }

  .custom-accordion-sub .accordion-header {
    height: 41px !important;
  }

  .accordion-button {
    padding: 10px 14px !important;
  }

  .custom-accordion-sub .Loader-icon {
    position: absolute;
    right: 8px;
    top: 5px;
    animation: round 4s linear infinite;
    width: 18px;
    height: 18px;
  }

  .custom-accordion .accordion-button.collapsed .accordion-arrow {
    margin-bottom: 4px;
  }

  /* Target Default Styles */
  .target-default {
    padding: 0px;
    color: white;
    border-radius: 4px;
    background: #398ffe;
    font-size: 10px;
    font-weight: 200;
    position: relative;
    left: 6px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;
    width: 53px;
  }

  /* Navbar Adjustments */
  .navbar-nav a {
    font-size: 14px;
  }

  .text-white .arrow-down {
    width: 20px;
    height: 20px;
  }

  .base-drop-down-arrow {
    width: 16px;
    height: 22px;
  }

  /* Summary Horizontal Item */
  .summary-horizontal-item {
    padding: 5px 5px 5px 0px;
  }

  .summary-horizontal-grid {
    margin-bottom: 0px;
  }

  /* List Group */
  .custom-list-group {
    width: 100%;
  }

  /* Cards Container */
  .cards-container .card {
    height: 145px !important;
  }

  .cards-container .card-title-container {
    margin-bottom: 0px !important;
  }

  .w-400 {
    width: 340px;
  }

  /* Manage Icon */
  .manage-icon {
    height: 18px;
    width: 16px;
  }

  /* Chart and Legend */
  .chart-and-legend .chart-legend-width {
    height: unset !important;
    width: 75px;
  }

  /* Table */
  .custom-table {
    border-spacing: 0 18px;
  }

  /* Animation Section */
  .animation-section {
    display: grid;
    grid-template-rows: 51% 50%; /* Video takes the top 50%, others take the bottom */
    grid-template-columns: 1fr 1fr; /* Logs and Stats sections take equal space */
    margin-bottom: 15px;
    height: 740px;
  }

  .video-container {
    grid-row: 1 / 2; /* Video section takes the top row */
    grid-column: 1 / 3; /* Span across both columns */
    border-bottom: 1px solid #ffffff;
    width: 100%;
    height: 315px;
    overflow: hidden;
  }

  .video-container video {
    width: 100%;
    object-fit: unset;
  }

  .logs-output {
    grid-row: 2 / 3; /* Bottom row */
    grid-column: 1 / 2; /* Left column */
    height: 425px;
    width: 100%; /* Ensure it fills the column */
    border-right: 1px solid #ffffff;
    border-left: unset;
  }

  .section-main {
    grid-row: 2 / 3; /* Bottom row */
    grid-column: 2 / 3; /* Right column */
    height: 425px;
    width: 100%; /* Ensure it fills the column */
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

  .issues-container-card {
    .security-highlight-card {
      height: 132px !important;
    }
    .security-highlight-card .text-highlights-title {
      font-size: 15px !important;
    }
    .card-title {
      height: 14px;
      margin-bottom: 0px;
    }
    .card-body {
      padding: 0;
    }
    .generic-card {
      height: 132px;
      padding: 14px;
    }
    .canvas-doughnut-graph {
      height: 75px;
    }
  }

  .sort-dp {
    width: 300px;
  }
  .securityissueCard_main_secondary {
    background-size: 83% 100%;
    padding: 15px 0px 15px 15px;
  }

  .securityissueCard_main_tertiary {
    background-size: 83% 100%;
    padding: 15px 0px 15px 15px;
  }
  .tab-issue-title {
    font-size: 14px;
    font-weight: 700;
  }
  .group-tab-sub {
    font-weight: 600;
  }
  .group-tab-value {
    font-size: 16px;
    font-weight: 600;
  }
  .cves-popup {
    width: 5px !important;
    height: 18px !important;
  }
  .ports-list-table .right-ports-container .port-collapse-table {
    margin-left: 17px;
  }
  .group-issues {
    .securityissueCard_main_secondary {
      background-size: 90% 100%;
      padding: 6px 0px 6px 7px;
      min-height: 77px;
    }
    .assets-container {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .risk-score-container {
      display: block;
    }
    .securityissueCard_section_3 {
      margin-right: 6px;
    }
    .securityissueCard_section_3 .securityissueCard_section_3_text {
      margin-right: 4px !important ;
    }
    .elipses-container {
      margin-right: 0px !important;
      margin-left: 0px !important;
      margin-bottom: 36px;
    }
    .risk-p-r {
      padding-right: 0px !important;
    }
    .securityissueCard_main_tertiary {
      background-size: 90% 100% !important;
      padding: 15px 0px 15px 15px;
    }
  }

  .custom-accordion-sub .menu-disabled:after {
    top: 13px;
  }
  .securityissueCard_section_3_value_secondary {
    display: flex;
    justify-content: center;
  }
  .advance-filter-row {
    width: 700px;
  }

  .countent-cve-large {
    display: none;
  }
  .countent-cve-small {
    display: block;
  }
  .w-97 {
    width: 97%;
  }
  .status-issues-chart .chart-legend-set {
    padding-left: 0px;
  }
  .status-issues-chart .chart-legend-width {
    width: 130px;
  }

  .assets-column-container {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  .asset-link {
    word-break: break-word;
  }
  .securityissueCard_section_3 {
    margin-left: 15px;
  }
  .list_header {
    .assets-comp {
      margin-top: 2.5rem;
    }
  }
  .custom-add-button {
    margin-left: 10px;
  }
  .manage-columns-button,
  .table-export,
  .add-new-asset {
    font-size: 18px;
  }
  .group-category-value {
    margin-top: 3px;
  }
  .securityissueCard_main_primary {
    background-size: 82% 100% !important;
  }

.custom-add-button {

  margin-left: 10px; 
}
.manage-columns-button,
.table-export,
.add-new-asset {
  font-size: 18px;
}
.group-category-value{
  margin-top: 3px;
}
.ricks-options{
  display: block;
}
.res-mt-2{
  margin-top: 0.5rem;
}
.cve-popup-container{
  display: block;
  .cvs-epss-popup-section{
    display: flex;
    align-items: center;
    .epss-popup-container{
      margin-left: 1rem;
    }
   
  }
  .cisa-kev-popup-container{
    margin-top: 0.5rem;
  }
}
.risk-score-popup-container{
  display: flex;
  gap: 1rem;
}
}

