.active-link {
  color: #398ffe !important;
}
.NavTabs-styling {
  border-bottom: 1px solid #404040;
  padding: 22px 30px 0px 30px;
  gap: 35px;
}
.nav-link.disabled {
  color: #e5e5e5;
  background-color: #3a3a3a;
  padding: 8px;
}
