.admin-dashboard {
  padding: 2.5rem 0rem 0rem 0rem !important;
  .view-by-container {
    padding: 0rem 2rem 0rem 2rem !important;
  }
  .tab-content {
    padding: 25px;
  }
}

.base-filter-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  .add-organization {
    color: #398ffe;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
  }
  .add-button {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    min-width: fit-content;
    svg {
      width: 18px;
      height: 16px;
    }
  }
  .left-filter {
    width: 100%;
  }
}

.admin-popover {
  background: #0e0e0e;
  border: 1px solid #3785ec;
  padding: 9px !important;
  .popover-item {
    width: 190px;
    padding: 9px 12px;
    gap: 4px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    color: #ffffff;
    text-align: left;
    cursor: pointer;
  }
  .popover-item:hover {
    background: #4a4a4a;
  }
}

.admin-target-modal {
  width: 649px;
  top: 426px;
  left: 635px;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid #93c2ff;
  background: #252525 !important;
  .admin-checkbox {
    width: 100%;
    margin-left: 25px;
  }
  .Admin-button {
    display: flex;
    justify-content: end;
    width: 100%;
    padding-right: 20px;
    gap: 10px;
  }
  h3 {
    width: 585px;
    gap: 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
    text-align: left;
  }
  h5 {
    width: 585px;
    gap: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-top: 10px;
  }
  .target-button {
    background: #252525;
    color: #93c2ff;
  }
  .target-button:hover {
    background: #398ffe;
    color: #ffffff;
  }
}

.user-target-modal {
  width: 649px;
  height: 200px;
  top: 426px;
  left: 635px;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid #93c2ff;
  background: #252525 !important;
  .admin-user-button {
    display: flex;
    justify-content: end;
    width: 100%;
    padding-right: 20px;
    gap: 10px;
  }
  h3 {
    width: 585px;
    gap: 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
    text-align: left;
  }
  h5 {
    width: 585px;
    height: 22px;
    gap: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  .target-button {
    background: #252525;
    color: #93c2ff;
  }
  .target-button:hover {
    background: #398ffe;
    color: #ffffff;
  }
}
// add organization modal

.custom-modal-width .modal-dialog {
  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #ffffff;
  }
  p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-decoration-skip-ink: none;
  }
  .another-user-link {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    text-decoration-skip-ink: none;
    color: #398ffe;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 9px;
      width: 18px;
      height: 20px;
    }
  }
  .another-user-container {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 15px 0px 20px;
  }
  .custom-select {
    color: #6f6f6f;
    background-color: #252525;
    font-size: 16px;
    height: 50px;

    appearance: none;
    background-image: url("../../../images/down-arrow-admin.svg");
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 16px;
    padding-right: 30px;
  }
  .custom-select:disabled {
    background-image: url("../../../images/down-arrow-admin-white.svg");
  }

  .custom-select option {
    background-color: #252525;
    color: #fff;
    font-size: 16px;
    padding: 8px;
  }
  // .custom-select:disabled {
  //   cursor: no-drop !important;
  //   background-color: #16283c80 !important;
  // }

  .placeholder-custom::placeholder {
    color: #6f6f6f;
  }
}
.Add-User-modal-width .modal-dialog {
  h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 31.2px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #ffffff;
  }
  p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-decoration-skip-ink: none;
  }
  .another-user-link {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    text-decoration-skip-ink: none;
    color: #398ffe;
  }
  .custom-select {
    color: #6f6f6f;
    background-color: #252525;
    font-size: 16px;
    height: 50px;
  }

  .custom-select option {
    background-color: #252525;
    color: #fff;
    font-size: 16px;
    padding: 8px;
  }

  .placeholder-custom::placeholder {
    color: #6f6f6f;
  }
}

.org-button {
  .button-hover:hover {
    background-color: #398ffe !important;
    color: #ffffff !important;
  }
}

// add organization modal succes
.modal-success {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: "#252525";
  color: "#fff";
  height: "234px";
  width: "593px";
  border: "1px solid #93C2FF";
}

.base-input {
  background-color: #0a0a0e;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
}
.base-input:focus {
  background-color: #0a0a0e !important;
  outline: none !important;
  color: #ffffff;
  border: none;
}
.editicon {
  border: 1px solid #398ffe;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
}
.edit-input {
  .search-container {
    padding: 0 !important;
  }
}
.admin-security-modal {
  .admin-input-label {
    color: #b8b8b8;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  input[type="date"] {
    color: #fff;
    background-color: transparent; 
    border: 1px solid #fff; 
    cursor: pointer;
  }
  .admin-input-field {
    color: #fff;
    font-size: 16px;
    height: 50px;
    border-color: #ffffff;
    margin: 8px 0px;
    border-radius: 5px;
    background-color: #252525;
  }
  .admin-text-field {
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    border-color: #ffffff;
    margin: 8px 0px;
    padding-top: 12px;
    background-color: #252525;
  }
}
.admin-asset-chip {
  border: 1px solid white;
  border-radius: 5px;
  padding: 12px 10px;
  margin-bottom: 6px;
  .admin-asset-chip-box {
    background-color: #525253;
    color: rgb(255, 255, 255);
    padding: 3px 7px;
    border-radius: 4px;
    margin-right: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 8px;
      cursor: pointer;
      width: 11px;
      height: 11px;
    }
  }
  input {
    margin: 0;
    padding: 0px 7px;
    font-size: 16px;
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
  }
  input:focus {
    border: none !important;
    color: white !important;
  }
  input::placeholder {
    color: #6f6f6f !important;
  }
}
.tested-tag-badge {
  color: rgb(57, 143, 254);
  border: 1px solid rgb(57, 143, 254);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 4px 14px;
  font-size: 14px;
  font-weight: 700;
  svg {
    width: 14px;
    margin-right: 5px;
  }
}

.tag-selector-container {
  position: relative;
  margin-top: 8px;
  .selected-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 9px 15px;
    background-color: #252525;
    color: #fff;
    cursor: pointer;
  }
  .unselected-badge {
    padding: 3px 0px;
    color: #6f6f6f;
  }
  .tag-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #252525;
    border: 1px solid white;
    border-radius: 5px;
    margin-top: 5px;
    padding: 10px;
    z-index: 1000;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .tag-option {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #333;
    color: #fff;
    font-size: 14px;
    transition: background-color 0.2s ease;
  }
}
.related-assets-box {
  border: 1px solid white;
  border-radius: 5px;
  margin-top: 6px;
  padding: 0px 20px 14px;
}

.admin-input-field:disabled {
  background-color: #686868 !important;
  color: white !important;
  border-color: #686868 !important;
}

.admin-text-field:disabled {
  background-color: #686868 !important;
  color: white !important;
  border-color: #686868 !important;
}
.admin-asset-chip-disabled {
  background-color: #686868 !important;
  color: white !important;
  border-color: #686868 !important;
  pointer-events: none;
}
.selected-tags-disabled {
  background-image: url("../../../images/down-arrow-admin-white.svg") !important;
  background-color: #686868 !important;
  color: white !important;
  border-color: #686868 !important;
  pointer-events: none;
}

.whatsnew-hr {
  margin-left: 13px;
  width: 97%;
  margin-top: 24px;
  border: 1px solid #a39c9c;
}

.whatsnew-user-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px 20px;

  .left-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #ffffff;
  }
}
.sub-user-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px 20px;
  .sub-update {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
.add-login-button {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  min-width: 305px;
}
.add-whatsnew-button {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  min-width: 280px;
}
.preview-mode {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
}

.sub-modal-container {
  border: 1px solid #d5d8dc;
  border-radius: 4px;
  .top {
    padding: 16px 12px 16px 20px;
    // .top-main {
    //   display: flex;
    //   align-items: center;
    //   justify-content: start;
    //   gap: 15px;
    // }
    // .top-spans {
    //   .date {
    //     font-size: 12px;
    //     font-weight: 600;
    //     line-height: 22px;
    //     color: rgba(255, 255, 255, 0.8);
    //   }
    //   .title {
    //     font-size: 16px;
    //     font-weight: 700;
    //     line-height: 22px;
    //     color: #ffffff;
    //   }
    // }

    .top-main {
      display: flex;
      align-items: center;
      font-family: Arial, sans-serif;
      color: white;
      border-radius: 8px;
    }

    .top-main .date {
      font-size: 14px;
      color: #bbb;
      margin-right: 15px;
    }

    .top-spans {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .top-spans .title {
      font-size: 16px;
      color: #ffffff;
      font-weight: 500;
    }

    .top-spans .ms-4 {
      margin-left: 10px;
    }

    .top-spans .title.ui-update {
      background-color: #e6fffb;
      color: #006d75;
      padding: 3px 8px;
      font-size: 11px;
      font-weight: 400;
      border-radius: 4px;
      cursor: pointer;
    }

    .top-spans .title.new {
      background-color: #d1e5ff;
      color: #3e90ff;
      cursor: pointer;
      padding: 3px 8px;
      font-size: 11px;
      font-weight: 400;
      border-radius: 4px;
    }

    .right-arrow {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    .right-arrow img {
      height: 18px;
      width: 18px;
    }
  }
  .bottom {
    padding: 16px 50px 16px 20px;
    background-color: #141416;
    hr {
      width: 100%;
      margin-top: 24px;
      border: 1px solid #a39c9c;
    }
    .bottom-main {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      .visit-screen {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        color: #398ffe;
        cursor: pointer;
      }
      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #ffffff;
      }
    }
  }
}

.admin-checkbox-disabled .form-check-input {
  width: 16px;
  height: 16px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  display: inline-block;
  margin-right: 10px;
}

.admin-checkbox-disabled .form-check-input:checked {
  background-color: #c5c5c5 !important;
  border-color: #c5c5c5 !important;
  background-image: url("../../../images/gray-checked.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 19px 15px;
}

.admin-checkbox-disabled .form-check-input:hover {
  border-color: #a5a5a5;
  background-color: #f5f5f5;
}

.admin-pay-attention {
  h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
}
.add-pay-attention-modal {
  .modal-dialog {
    max-width: 605px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
