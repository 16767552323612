  .breadcrumb-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .breadcrumb {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 0;
    }
  .breadcrumb-item + .breadcrumb-item::before {
      content: ":";
      font-weight: 400;
    }
  
  .breadcrumb-item {
      font-size: 1.1rem;
      font-weight: 400;
      margin: 0 0 0 0;
  
  }
  
  .breadcrumb-item.active {
      font-size: 1.2rem;
      color: rgb(255, 255, 255);
      font-weight: 600;
  }
  a{
    color: white;
  }
  
  .horizontal-rule {
    border-bottom: 1px solid white;
  }
  
  
  