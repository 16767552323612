.manage-columns-container {
  position: relative;
}

.manage-columns-button {
  display: flex;
  align-items: center;
  justify-items: center;
  color: #2584f4 !important;
  font-weight: bold;
  cursor: pointer;
  background-color: transparent;
  padding: 5px 10px;
  margin-left: 25px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.manage-columns-button:hover,
.manage-columns-button.active {
  color: #08499d !important;
  background-color: #bdcadb;
  path {
    fill: #08499d !important;
  }
}

.manage-icon {
  margin-right: 5px;
}

.custom-dropdown {
  position: absolute;
  top: 100%;
  width: 496px;
  background-color: #0a0a0e;
  color: white;
  padding: 30px 25px;
  border: 1px solid #398ffe;
  border-radius: 4px;
  min-width: 200px;
  z-index: 1000;
  left: -260px !important;
}

.select-clear-all {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  cursor: pointer;
  span {
    color: #398ffe;
  }
}

.dropdown-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
}

.dropdown-item {
  border-radius: 2px 0px 0px 0px;
  padding: 8px 12px 8px 12px;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #0e0e0e;
}

.dropdown-item input[type="checkbox"] {
  margin-right: 8px;
}

.crack-hash-button {
  display: flex;
  align-items: center;
  justify-items: center;
  color: #2584f4 !important;
  font-weight: bold;
  cursor: pointer;
  background-color: transparent;
  padding: 5px 10px;
  margin-left: 25px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.crack-hash-button:hover,
.crack-hash-button.active {
  color: #87c2f3 !important;
  // background-color: #bdcadb;
  path {
    fill: #87c2f3 !important;
  }
}

.crack-hash-button.disable {
  color: #767272 !important;
  cursor: no-drop;
  background-color: transparent;
  path {
    fill: #767272 !important;
  }
}
