// asset inventory css
.asset-discovery-item {
  .arrow-rotated-right {
    transform: rotate(-90deg);
    transition: transform 0.3s;
  }

  .arrow-rotated-down {
    transform: rotate(0deg);
    transition: transform 0.3s;
  }

  .asset-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    span {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20.8px;
      color: #f5f5f5;
    }
  }
  .asset-content {
    background: #272728;
    padding: 0px 24px 0px 24px;;
    margin-top: 16px;
    .quick-main{
      padding-top: 28px;
    }
    .quick-domain-approval {
      background-color: #272728;
      color: white;
      padding: 10px 20px 0px 15px;
      border-radius: 5px;
      position: relative;
      width: 100%;
      border: 1px solid white;
      border-left-width: 6px;
      margin-bottom:  10px;
    }

    .quick-domain-content {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    .icon-wrapper {
      flex-shrink: 0;
      margin-right: 10px;
    }

    .text-content h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 22px;
    }

    .text-content p {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.8);
    }

    .close-button {
      position: absolute;
      top: -4px;
      right: 15px;
      background: none;
      border: none;
      color: white;
      font-size: 36px;
      cursor: pointer;
    }

    .domain-checkbox {
      position: relative;
      top: -1px;
    }
    .select-domains {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      color: #ececec;
      margin-left: -8px;
    }
    .domain-clear {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      color: #959090;
      cursor: pointer;
      text-decoration: underline !important;
    }
    .domain-saperator {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
    }
    .domain-saperate {
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      color: #ffffff;
    }
    .scan-domain {
      font-size: 20px;
      margin-left: 15px;
      font-weight: 700;
      line-height: 22px;
      color: #398ffe;
      cursor: pointer;
    }
    .domain-row {
      max-height: 427px;
      overflow-y: auto;
    }

    .domain-row::-webkit-scrollbar {
      width: 6px;
    }

    .domain-row::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 4px;
      border: 2px solid #f5f5f5;
    }
  }
}

// scan domain css

.scan-domains-component {
  width: 100%;
  // margin-left: 10px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  background: linear-gradient(
    4.67deg,
    rgba(90, 90, 91, 0) -25.21%,
    #5a5a5b 130.89%
  );
  border: 1px solid #636363;
  padding: 16px 24px 16px 12px;
  border-radius: 5px;
}
.scan-domains-component.selected-border {
  border: 2px solid #398FFE;
}
.scan-domain-text {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #ffffffe5;
  }
}
.scan-domains-button {
  display: flex;
  height: 35px;
  padding: 5px 12px;
  gap: 4px;
  border-radius: 3px;
  border: 1px solid #398ffe;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #ffffffe5;
  }
}
.scan-domains-button:hover {
  border-color: #398ffe;
}
.scan-domains-button.active,
.scan-domains-button:focus {
  background-color: #e2efff;
  path {
    fill: #398ffe !important;
  }
  span {
    color: #398ffe;
  }
}

// Domain scan table css

.domain-scan-table {
  background: rgba(39, 39, 40, 1);
  // border-radius: 4px;
  padding: 16px;
  margin-bottom: 17px;
  color: #f5f5f5;
  .domain-name {
    margin-bottom: 2px;
  }
  .arrow-rotated-right {
    transform: rotate(-90deg);
    transition: transform 0.3s;
  }
  .arrow-rotated-down {
    transform: rotate(0deg);
    transition: transform 0.3s;
  }
  .domain-asset-header {
    cursor: pointer;
    display: flex;
    height: 60px;
    align-items: center;
    padding: 10px 16px;
    justify-content: space-between;

    .domain-table-heading {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20.8px;
    }
    .domain-header-start {
      font-size: 14px;
      font-weight: 400;
      padding-top: 1px;
    }
    .domain-header-end {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-right: 15px;
    }
    .domain-saperator {
      font-size: 16px;
      font-weight: 600;
    }
    .scan {
      border: 2px solid #398ffe;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      padding: 4px 12px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      
     
    }
    .scan:focus {
      background-color: #e2efff;
      color: #398ffe;
    }

    .scan:focus path {
      fill: #398ffe !important;
    }

    .scan:focus span {
      color: #398ffe;
    }
  }

  .domain-asset-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .port-collapse-table {
      margin-bottom: 15px;
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          background-color: #282829;
          border-bottom: 1px solid #565656;
          tr {
            th {
              font-size: 16px;
              font-weight: 700;
              line-height: 18.2px;
              text-align: left;
              padding: 16px 10px;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #565656;
            td {
              font-size: 16px;
              font-weight: 400;
              line-height: 18.2px;
              text-align: left;
              padding: 16px 10px;

              .action-icons {
                display: flex;
                gap: 10px;

                .action-cancel {
                  cursor: pointer;
                  color: #fff;
                  border: 1px solid #FFFFFF;
                  padding: 3px 5px;
                  border-radius: 5px;
                }
                .action-check {
                  cursor: pointer;
                  color: #fff;
                  border: 1px solid #FFFFFF;
                  padding: 3px 4px;
                  border-radius: 5px;
                  outline: none;
                }

                .action-check:focus,
                .action-cancel:focus {
                  background-color: #e2efff;
                  border-color: #398FFE;
                }

                .action-check:focus path,
                .action-cancel:focus path {
                  fill: #398ffe !important;
                }

                .action-check:focus span,
                .action-cancel:focus span {
                  color: #398ffe;
                }
                .action-disable {
                  pointer-events: none; 
                  color: #fff;
                  opacity: 0.5;
                  border: 1px solid #686868 !important;
                  padding: 3px 4px;
                  border-radius: 5px;
                }
              }
            }
          }
          tr:last-of-type {
            border-bottom: none;
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

// confirmation modal css
.single-domain {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  padding: 12px;
  background-color: #373737;
  margin-top: 24px;
  span {
    color: #398ffe;
    text-decoration: underline;
    cursor: pointer;
  }
}
.asset-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.asset-modal-content {
  background: #252525;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #93c2ff;
  text-align: center;
  height: auto;
  max-width: 900px;
  width: 100%;

  .domain-header {
    display: flex;
    align-items: center;
  }
  .list-domains {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: white;
    margin-bottom: 8px;
  }
  .domain-list::-webkit-scrollbar {
    width: 4px;
  }

  .domain-list::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 4px;
    border: 2px solid #f5f5f5;
  }

  h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    margin-bottom: 16px;
    overflow-wrap: anywhere;
    color: #ffffff;
  }
  p {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 24px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0px;
  }
}

.domain-name {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.asset-modal-button {
  width: 80px;
  height: 40px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #398ffe;
  color: #398ffe;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
}

.asset-modal-button:hover {
  border: 1px solid #fff;
  color: #fff;
  background: #398ffe;
}

.asset-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.no-domains-box {
  margin-top: 16px;
  display: flex;
  height: 215px;
  padding: 46px 80px;
  gap: 0px;
  background:#272728;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    text-align: center;
    margin: 0;
    color: #ffffff;
  }
}

.empty {
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    h4 {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: 600;
      line-height: 31.2px;
      color: #ffffff;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 20.8px;
      text-align: center;
      color: #f5f5f5;
    }
  }
}