.updates-container {
    width: 220px;
    padding: 20px;
    background-color: #2b2b2b;
    border-radius: 12px;
    // font-family: 'Arial', sans-serif;
    color: #b8d9c4;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .updates-title {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .updates-grid {
    width: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    position: relative;
  }
  
  .updates-grid::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    border-left: 1px dashed #4a4a4a;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  
  .updates-grid::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    border-top: 1px dashed #4a4a4a;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .icon-style {
    padding-bottom: 5px;
    padding-left: 4px;
  }

  #tooltip-Removed {
    .comming-tool-body {
      padding: 10px 10px 10px 10px;
      text-align: center;
      margin-right: 0;
    }
  }

  
  .updates-item {
    padding: 5px 10px;
    font-size: 13px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .updates-label {
    font-size: 12px;
    color: #d5f3e9;
    margin-bottom: 8px;
  }
  
  .updates-value {
    font-size: 20px;
    color: #b8d9c4;
    font-weight: bold;
  }
  