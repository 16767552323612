/*--------------------------------------------------------------------------------
### Utilities -> Common -> Margins
--------------------------------------------------------------------------------*/
.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}
