// ******************** waf Doughnut card scss **************************
.chart-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.bar-chart-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 3px;
  height: 200px !important;
  width: 100%;
}

.bar-chart-container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: hidden;
}

// ******************** Comparison Chart scss **************************

.offensecard_chart {
  width: 32%;
  height: 65%;
  padding: 1px;
  margin-top: 0.2rem;

  .chart_top_value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 25px;
    padding-bottom: 4px;
    font-size: 11px;
  }

  .chart_bottom_value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 25px;
    padding-top: 4px;
    font-size: 11px;
  }

  canvas {
    width: 100%;
    height: 100%;
  }
}

.offensecard_results {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  height: 12rem;
  color: #ffffff;

  .offensecard_results_title {
    font-weight: 700;
  }

  .offensecard_results_content {
    display: flex;
    align-items: center;
    justify-content: center;

    .offensecard_results_text {
      width: 100%;
    }

    .offensecard_success {
      font-weight: 700;
      color: #ff4a4a;
      font-size: 1rem;
    }

    .offensecard_failed {
      font-weight: 700;
      color: #3ddc97;
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }
}

.bar-graph {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: transparent;
  width: 100%; // Full width of the container
  height: 3rem; // Height of the graph container
  box-sizing: border-box;
  border-bottom: 2px solid #ffffff;

  .bar-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-end;
  }

  .bar2 {
    flex: 1; // Each bar takes up equal space in the container
    margin: 0 0.7rem; // Space between bars
    display: flex;
    align-items: flex-end;
    position: relative;

    .bar-value {
      position: absolute;
      bottom: 100%; // Position the value label above the bar
      left: 50%;
      transform: translateX(-50%); // Center the label
      color: white;
      font-weight: bold;
      padding: 2px 5px;
    }
  }

  .bar1 {
    flex: 1; // Each bar takes up equal space in the container
    margin: 0 0.7rem; // Space between bars
    display: flex;
    align-items: flex-end;
    position: relative;
    top: 3rem;
    transform: scaleY(-1);

    .bar-value {
      position: absolute;
      bottom: 100%; // Position the value label above the bar
      left: 31%;
      transform: translateX(-50%); // Center the label
      color: white;
      font-weight: bold;
      padding: 2px 5px;
      transform: scaleY(-1);
    }
  }
}

// ******************** Pie Chart scss **************************

.chart-and-legend {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .chart-legend-width {
    width: 209px;
    height: 120px;
    position: relative;
    top: -16px;
  }
  .chart-legend-set {
    padding-left: 30px;
    width: 40%;
  }

  .legend-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    .legend-span {
      display: flex;
      align-items: center;

      .legend-box {
        width: 12px;
        height: 12px;
        display: inline-block;
      }

      .legend-box-label {
        color: white;
        font-size: 11px;
        margin-left: 10px;
      }
    }

    .legend-box-value {
      color: white;
      border-left: 2px solid gray;
      width: 36px;
      text-align: center;
      font-size: 11px;
    }
  }
}

.chart-with-position{
  .chart-and-legend {
    .chart-legend-width {
      position: relative;
      top: 0px !important;
    }
  }
}

// ***************** VerticalBar Chart scss ************************

.VerticalBarChart_main {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}

//security-issues-chart
.status-issues-chart {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .chart-legend-width{
    width: 209px;
  }
  .chart-legend-set{
    width: 40%;
  }

  .legend-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    .legend-span {
      display: flex;
      align-items: center;

      .legend-box {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 2px;
      }

      .legend-box-label {
        color: #F5F5F5;
        font-size: 10px;
        margin-left: 10px;
        line-height: 13px;
      }
    }

    .legend-box-value {
      color: #F5F5F5;
      border-left: 2px solid #F5F5F5;
      width: 36px;
      text-align: center;
      font-size: 10px;
      font-weight: 700;
    }
  }
}
