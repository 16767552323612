/* ************************** update-Card.css ********************************** */

.update-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .update-card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .update-card-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3{
        margin: 0;
    }
  }

  .uploaded-text{
    font-size: 1rem;
    font-weight: 700;
    h3{
        margin: 0;
    }
    .upload-num{
        color:  #BCF4DE;
        ;
    }
  }

  .view-link {
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 600;
  }
  
  .web-updates-container{
    @media (max-width: 1500px) {
      .web-updates-col-7 {
        flex: 0 0 58.333333% !important;
        max-width: 58.333333% !important;
      }
      .web-updates-col-5 {
        flex: 0 0 41.666667% !important;
        max-width: 41.666667% !important;
      }
    }
  }