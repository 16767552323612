.statsCard_main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    color: #ffffff;
    background-image: linear-gradient(180deg, #5a5a5b, #5a5a5b84);
    border-radius: 3px;
    flex-wrap: wrap;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 0.5rem;
    height: 9.125rem;
    width: 21rem;

    .statsCard_text{
        width: 100%;
        font-weight: 700;
        height: 30%;
        display: flex;
    }
    .statsCard_text_center{
        justify-content: center;
        align-items: center;
    }

    .statsCard_value{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
        font-weight: 700;
        width: 100%;
        height: 70%;

    }
}