.modal-breadcrum-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.host-name {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}
.host-name:hover {
  color: #87c2f3;
  cursor: pointer;
}
.host-link {
  font-weight: 400;
  margin-left: 5px;
}
.arrow-right {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-right: 0px;
  position: relative;
  bottom: 1px;
}
.asset-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #a39c9c;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.asset-card-title {
  color: #e5e5e5;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}
.sub-categories-title-scroll {
  position: relative;
  margin-top: 31px;
  bottom: 29px;
}
.asset-card-desc {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.ip-desc {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
.ip-desc:hover {
  color: #87c2f3;
}
.asset-subicon-desc {
  display: flex;
  gap: 5px;
}
.sub-categories-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  bottom: 5px;
  height: 41px;
  overflow-y: auto;
  padding: 0px 10px;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
}
.cancel-section {
  cursor: pointer;
}
.security-header {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 10px;
}
.signals-risk-section {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 30px;
}
.risk-asset {
  color: #f5f5f5;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
}
.risk-asset:hover {
  color: #87c2f3 !important;
  cursor: pointer;
}
.risk-icon-section {
  display: flex;
  align-items: center;
  gap: 8px;
}
.not-found {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.assets-tags {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  background-color: #636363;
  border-radius: 4px;
  padding: 2px 8px;
  cursor: pointer;
}
.assets-tags:hover {
  color: #87c2f3;
}
.assets-host-tags {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  background-color: #636363;
  border-radius: 4px;
  text-decoration: underline;
  padding: 2px 8px 2px 8px;
  cursor: pointer;
}
.assets-card-tags {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  background-color: #636363;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  cursor: pointer;
  word-break: break-word;
  text-align: center;
}
.assets-host-tags:hover {
  color: #87c2f3;
}
.assets-tags-section {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.asset-body-footer {
  display: flex;
  flex-direction: column;
  gap: 150px;
}
.close-btn {
  width: 70px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #398ffe;
  border-radius: 4px;
  color: #398ffe;
  font-size: 16px;
  font-weight: 700;
}
.btn-section {
  display: flex;
  justify-content: end;
}
.tag-host-header {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.host-tag-icon-section {
  display: flex;
  align-items: start;
  gap: 10px;
  margin-bottom: 20px;
}
.assets-card-row {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.assets-card-col {
  width: calc(20% - 12px);
  height: 145px;
}
.processing-spinner {
  display: flex;
  font-weight: 400;
  align-items: center;
  svg {
    margin-right: 8px;
    animation: round 4s linear infinite;
  }
}
.asset-tooltip-scroll {
  max-height: 500px !important;
  overflow: auto;
  margin-right: 0px !important;
  &::-webkit-scrollbar {
    width: 9px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background: #120b15;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #120b15;
  }
}
.no-hover-asset {
  color: white !important;
  cursor: initial !important;
}

.disabled-option{
  opacity: 0.6;
  pointer-events: none;
}