/* -------------------------------------------------------------------------------
#### Components -> Auth -> Common Base
------------------------------------------------------------------------------- */
@media only screen and (min-width: 768px) {
  
  .auth {
    width: 560px;
    height: 100vh !important;
    background-image: url("../../../../images/loginBG.png");
  }
}
.auth {
  width: 560px;
  height: 100vh;
  .sub{
    height: 100vh;
  }
}
@media only screen and (max-width: 768px) {
.otp {
    margin-right: 10px !important;
  }
}

.password-eye-position{
  position: absolute;
   right: 10px;
   top: 50%;
    transform: translateY(-50%);
    cursor: pointer
}
.password-field{
  background: none !important;
  
}

// login
.login-padding{
  margin-top: 80px;
}
.login-armory-forget{
    margin: -10px 0px 0px;
  }
// form
.invitation-form-set{
  max-width: 500px;
}
.form-height-set{
  .form-control-height {
    height: 63px; 
  }
  
}

// csv main

.csv-main {
  .btn-custom {
    width: 170px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-upload {
    fill: #ffffff;
    margin-left: 10px;
    height: 20px;
    width: 20px;
  }

  .d-none {
    display: none;
  }
}
// delete
.delete-main i {
  color: white; 
  cursor: pointer; 
}

.delete-main i:hover {
  color: white; 
}



