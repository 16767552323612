.certificate-table {
  .sub-table-container {
    overflow: auto;
    margin-bottom: 20px;

    .custom-table {
      // width: 1850px !important;
    }

    .table-responsive {
      position: relative;
    }

    .table-responsive::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .table-responsive::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 10px;
    }

    .table-responsive::-webkit-scrollbar:hover {
      width: 2px;
    }

    .table-responsive::-webkit-scrollbar {
      height: 5px;
    }
  }
}

.hostname-link {
  background-color: #525253;
  color: #ffffff;
  padding: 1px 12px;
  border-radius: 4px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  text-decoration: underline;
}

.data-leaks {
  .card-body {
    div {
      margin-bottom: 0 !important;
    }
  }
}

.data-leaks-card-text {
  font-size: 14px;
  font-weight: 400;
}

.data-leaks-card-value {
  font-size: 40px;
  font-weight: 700;
}

.sticky-table-scroll {
  position: sticky;
  bottom: 0px;
  z-index: 10;
  height: 20px;
  background: #100e10;

  .sticky-table-scroll-box {
    display: none;
    cursor: pointer;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #120b15;
    }

    &::-webkit-scrollbar-thumb {
      background: #ffffff;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
  }
}

.mfa-fragment {
  margin-bottom: 150px;

  .mfa-main {
    margin-top: 150px;
    background: #1b1b1c;
    padding: 34px 88px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    .form-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      color: #ffffffe5;
    }

    .mfa-form {
      width: 664px;
      margin: auto;

      .mfa-input {
        width: 70%;
        margin: auto;
      }

      .form-control {
        margin: 5px 0px 0px 0px;
      }

      .form-control:not(.is-invalid) {
        border-color: white !important;
      }

      .form-control::placeholder {
        color: #6f6f6f !important;
        font-size: 16px;
      }
    }
  }
  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    color: #ffffff;
    pointer-events: none;
    background-color: #767272;
    border: none !important;
    opacity: unset !important;
  }
}

.resend-code {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  color: #398ffe;
}
.resend-code-disable {
  pointer-events: none;
  cursor: not-allowed;
  color: #767272;
}

.hr-line {
  border: 1px solid #454545;
}

.form-footer-container {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #d9d9d9;

  a {
    color: #d9d9d9;
  }
}
.mfa-input {
  div {
    justify-content: center !important;
  }
}
.mfa-input .otp-input {
  width: 50px !important;
  height: 50px;
  margin-right: 15px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid #ffffff;
  font-size: 28px;
  font-weight: 600;
  line-height: 22px;
  color: #ffffff;
}

.error-input .otp-input {
  border: 1px solid #FF6155 !important;
}
.mfa-input .otp-input:last-child {
  margin-right: 0;
}
.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input[type="number"] {
  -moz-appearance: textfield; 
}

.mfa-error-message {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #ff6155;
}

.error-section-container {
  padding: 14px 14px 0px;
}
