.table-container {
  margin-bottom: 5px;
  height: 40px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.table-left {
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.search-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--white);
  border-radius: 5px;
  height: 40px;
  padding: 12px;
}

.search-input {
  border: none;
  background: none;
  outline: none;
  color: var(--search-text-color);
  font-size: 16px;
  font-weight: 400;
  flex: 1;
  padding: 10px 5px;
  text-align: left;
  &::placeholder {
    color: var(--search-text-color) !important;
  }
}
.w-400{
  width: 400px;
}

.search-input:focus {
  border: none !important;
}

.search-button {
  background: none;
  border: none;
  color: var(--white);
  cursor: pointer;
  padding: 0;
}

.table-result {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: left;
  color: #F5F5F5;
}
.rounded-2{
  border-radius: 2px !important;
}
.table-export {
  font-size: 20px;
  font-weight: 700;
  line-height: 20.8px;
  text-align: left;
  color: var(--link-color);
}

.filter-icon {
  width: 40px;
  height: 40px;
  margin-left: 30px;
  gap: 2px;
  border-radius: 2px 0px 0px 0px;
  border: 1px solid var(--white);
  padding: 10px;
}

.export-icon path {
  fill: var(--link-color);
}
.base-table-container{
 height: 500px;
}

.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  background-color: var(--custom-black-colo);

  thead {
    tr {
      background-color: var(--custom-black-colo);
    }

    th {
      padding: 16px;
      text-align: left;
      background-color: inherit;
      font-size: 14px;
      font-weight: 700;
      border: none;

      input[type="checkbox"] {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  tbody {
    tr {
      margin-bottom: 10px;
      --bs-table-bg: var(--table-bg-color);

      &:hover {
        background-color: var(--table-hover-color);
      }

      &.selected {
        background-color: var(--custom-table-row-selected-color);
      }

      td {
        padding: 16px;
        border-bottom: 1px solid transparent;
        font-size: 16px;
        font-weight: 400;
        input[type="checkbox"] {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
      .status-danger {
        background-color: var(--status-danger);
      }
      .status-success {
        background-color: var(--status-success);
      }
    }
  }
}
.secondary-badges {
  margin-bottom: 7px;
  font-size: 14px;
  margin-right: 10px;
  --bs-bg-opacity: 1;
  background-color: var(--secondary-badge-bg-color) !important;
  color: var(--white);
}
.icon-up-down {
  .bi-caret-up-fill,
  .bi-caret-down-fill {
    font-size: 12px;
    line-height: 11px;
  }
}

.custom-table {
  .text_secondary {
    color: var(--text-secondary-color);
  }
  .active {
    color: var(--white) !important;
  }
}



.primary-drop-down{
  border: 1px solid #FFFFFF;
  width: 214px;
  height: 40px;
  border-radius: 5px;
  background: transparent;
  
}
.text-link {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  color: var(--link-color);
  text-decoration: underline;
}
.text-link-http {
  font-size: 16px;
  font-weight: 400;
  line-height: 15.6px;
  
}

.apply-link-text{
  font-size: 16px;
  font-weight: 700;
  color: var(--link-color);
}
.action-dp-menu{
  background:  var(--app-dark);
  width: 100%;

}

.action-link-btn:disabled{
  color: var(--link-color);
}
.action-link-btn:hover{
  color: var(--link-color);
}

// tools dropdown active
.text-active{
  color: var(--link-color) !important;
  .arrow-up{
    transition: transform 0.3s ease-in-out;
    transform: rotate(0);
    path{
      fill:var(--link-color) !important ;
    }

  }
}
.text-white{
  color: #ffffff;
  .arrow-down{
    transition: transform 0.3s ease-in-out;
    transform:  rotate(-90deg);
    path{
      fill:#ffffff ;
    }

  }
}

.m-w-75{
  min-width: 75px;
}

.bulk-selection-container{
  background: #454545;
  border-radius: 2px;
}
.fs-16{
  font-size: 16px;
}

.clear-devider{
  color: rgb(245, 245, 245);
    font-size: 16px;
    font-weight: 400;
}