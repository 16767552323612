//HighlightsRisk
.HighlightsRisk_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #f5f5f5;
  font-size: 1.25rem;
  width: 100%;
  // margin-left: 5px;
  margin-top: -25px;

  .HighlightsRisk_title {
    font-weight: 700;
  }

  .HighlightsRisk_content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#signal-strength-high {
  height: 57px;
  list-style: none;
  overflow: hidden;
  margin-right: 0.7rem;
  margin-bottom: 13px;
  padding-left: 25px;

  li {
    display: inline-block;
    width: 0.3rem;
    height: 100%;
    margin-right: 2px;
  }

  li.strong {
    padding-top: 25px;
    padding-bottom: 12px;
  }

  li.weak {
    padding-top: 30px;
    padding-bottom: 12px;
  }

  li.very-weak {
    padding-top: 35px;
    padding-bottom: 12px;
  }

  li div {
    height: 100%;
    background: #ff6155;
    border-radius: 10px;
  }
}

#signal-strength-medium {
  height: 57px;
  list-style: none;
  overflow: hidden;
  margin-right: 0.7rem;
  margin-bottom: 13px;
  padding-left: 25px;

  li {
    display: inline-block;
    width: 0.3rem;
    height: 100%;
    margin-right: 2px;
  }

  li.strong {
    padding-top: 25px;
    padding-bottom: 12px;
  }

  li.weak {
    padding-top: 30px;
    padding-bottom: 12px;
  }

  li.very-weak {
    padding-top: 35px;
    padding-bottom: 12px;
  }

  li div {
    height: 100%;
    background: #ff9f45;
    border-radius: 10px;
  }

  li .greyBar {
    height: 100%;
    background: #686868;
    border-radius: 10px;
  }
}

#signal-strength-low {
  height: 57px;
  list-style: none;
  overflow: hidden;
  margin-right: 0.7rem;
  margin-bottom: 13px;
  padding-left: 25px;

  li {
    display: inline-block;
    width: 0.3rem;
    height: 100%;
    margin-right: 2px;
  }

  li.strong {
    padding-top: 25px;
    padding-bottom: 12px;
  }

  li.weak {
    padding-top: 30px;
    padding-bottom: 12px;
  }

  li.very-weak {
    padding-top: 35px;
    padding-bottom: 12px;
  }

  li div {
    height: 100%;
    background: #07c00e;
    border-radius: 10px;
  }

  li .greyBar {
    height: 100%;
    background: #686868;
    border-radius: 2px;
  }
}

//IssuesFinding
.issuesFinding_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem 0rem 0rem;

  .issuesFinding_title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #f5f5f5;
  }

  .search-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40rem;

    form {
      display: flex;
      background: transparent;
      border-radius: 5px;
      border: 1px solid #ffffff;
      width: 50%;

      input {
        border: none;
        background: transparent;
        color: #ffffff;
        padding: 5px 15px;
        width: 100%;
        outline: none;

        &::placeholder {
          color: #a7a7b1;
        }
      }

      button {
        background: transparent;
        border: none;
        padding: 5px 15px;
        cursor: pointer;
        outline: none;

        img {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-size: cover;
        }
      }
    }
  }
}

//OptionsSection
.optionsSection_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #f5f5f5;
  margin: 1rem 0rem 0rem 0rem;

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-toggle-button {
    background-color: transparent;
    color: #a7a7b1;
    padding: 6px 13px;
    border: 1px solid #ffffff;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    font-size: 16px;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 3rem;
    vertical-align: middle;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .dropdown-menu {
    display: block;
    position: absolute;
    background-color: #000000;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 5;
    border-radius: 4px;
    border: 2px solid rgb(57, 143, 254);
    margin-top: 10px;
    padding: 12px 15px;
    line-height: 37px;
  }

  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .dropdown-menu ul li {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }

  .dropdown-menu ul li:hover {
    background-color: #e9e9e9;
  }

  .optionsSection_left {
    display: flex;
    justify-content: center;
    align-items: center;

    .optionsSection_left_text {
      font-size: 1rem;
    }

    .optionsSection_left_filter {
      border: 1px solid #ffffff;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 2px;
    }
  }

  .export-button {
    border: none;
    padding: 8px 20px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    .export-icon {
      margin-right: 8px;
      fill: #398ffe;
    }

    .export-text {
      font-size: 1.25rem;
      color: #398ffe;
      font-weight: 700;
    }
  }
}

//SecurityIssueCard
$primary-color: #ff6155;
$secondary-color: #f3a738;
$tertiary-color: #3ddc97;

.SecurityIssueCard_Main {
  display: flex;
  align-items: center;
  // justify-content: space-evenly;
  width: 100%;
  // margin-left: 18px;
}

.SecurityIssueCard_checkbox {
  margin-right: 10px;

  input {
    cursor: pointer;
    border-color: rgba(167, 167, 177, 1) !important;
    margin: 0px 0px 0px -6px;
    position: relative;
    top: 17px;
  }
}

.securityissueCard_main_tertiary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #272728;
  background-image: url("../../../images/securityissueCard_bg.png");
  background-size: 87% 100%;
  // min-height: 10.313rem;
  width: 100%;
  // margin-top: 1.5rem;
  background-repeat: no-repeat;
  border-left: 6px solid $tertiary-color;
  color: #f5f5f5;
  padding: 15px 15px 15px 15px;
  position: relative;
}

.securityissueCard_main_secondary {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  background-color: #272728;
  background-image: url("../../../images/securityissueCard_bg.png");
  background-size: 87% 100%;
  // min-height: 10.313rem;
  width: 100%;
  // margin-top: 1.5rem;
  background-repeat: no-repeat;
  border-left: 6px solid $secondary-color;
  color: #f5f5f5;
  padding: 15px 15px 15px 15px;
  position: relative;
  min-height: 120px;
}

.securityissueCard_main_primary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #272728;
  background-image: url("../../../images/securityissueCard_bg.png");
  background-size: 87% 100%;
  // min-height: 10.313rem;
  width: 100%;
  // margin-top: 1.5rem;
  background-repeat: no-repeat;
  border-left: 6px solid $primary-color;
  color: #f5f5f5;
  padding: 10px;
  position: relative;
}

.securityissuemain {
  margin-top: 1.5rem;
}

.border-action {
  border: 2px solid #398ffe;
}

.verification {
  font-size: 13px;
  color: #ff6155;
  width: 168px;
  text-align: center;
  margin-top: 100%;
}

.securityissueCard_section_1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 45%;
  font-weight: 400;
  justify-content: space-between;

  .section_1_part1 {
    display: flex;
    align-items: start;
    justify-content: center;

    .securityissueCard_title {
      font-weight: 700;
      // cursor: pointer;
      font-size: 1rem;
    }

    .section_1_part1_subpart {
      margin-left: 55px;
      display: flex;
      align-items: center;
      justify-content: center;

      .securityissueCard_subtitle {
        color: #398ffe;
        font-weight: 700;
        margin-left: 0.5rem;
        width: 150px;
        font-size: 12px;
      }
    }
  }

  .section_1_part2 {
    font-size: 0.75rem;
    color: #e5e5e5;
    margin-top: 27px;
  }

  .section_1_part3 {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    margin: 8px 16px 8px 0px;

    .section_1_part3_subpart1 {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 2px 8px 2px 8px;
      border-radius: 4px;
    }

    .section_1_part3_subpart2 {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.2);
      margin-left: 1rem;
      padding: 2px 8px 2px 8px;
      border-radius: 4px;
    }
  }

  .section_1_part2 {
    font-size: 0.75rem;
  }

  .section_1_part4 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    margin-top: 15px;

    .section_1_part4_subpart {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 2px 8px 2px 8px;
      border-radius: 4px;
    }
  }

  .section_1_part5 {
    display: flex;
    align-items: center;
    font-size: 10px;
    width: 100%;

    .section_1_part5_subpart1 {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #e5e5e5;

      .section_1_part5_subpart1_sub {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.2);
        padding: 2px 8px 2px 8px;
        border-radius: 4px;
        margin-left: 0.7rem;
        color: #fff;
      }
    }
  }
}

.title-3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
}

.securityissueCard_section_2 {
  // display: flex;
  // align-items: center;
  // justify-content: space-around;
  width: 40%;
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 3%;

  .securityissueCard_section_2_part1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 3rem;
    color: #e5e5e5;

    .securityissueCard_section_2_part1_text {
      font-size: 0.875rem;
      color: #fff;
    }
  }

  .securityissueCard_section_2_part2 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 6rem;
    color: #e5e5e5;
    margin-left: 40px;

    .securityissueCard_section_2_part2_value {
      font-size: 1rem;
      font-weight: 400;
      color: #fff;
      margin-top: 6px;
    }

    .securityissueCard_section_2_part2_cvss {
      margin-top: 15px;
    }
  }

  .cvss_value_text {
    font-size: 1rem;
    font-weight: 400 !important;
    color: #fff;
  }

  .securityissueCard_section_2_part3 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 6rem;
    color: #e5e5e5;
    margin-left: 35px;

    .securityissueCard_section_2_part3_value {
      font-size: 1rem;
      font-weight: 400;
      color: #fff;
      margin-top: 6px;
    }

    .securityissueCard_section_2_part2_epss {
      margin-top: 15px;
    }
  }
}

.securityissueCard_section_3 {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  margin-right: 15px;

  .securityissueCard_section_3_text {
    font-size: 14px !important;
    margin-right: 5px;
  }
}

.securityissueCard_menu {
  position: absolute;
  right: 1rem;
  top: 1.3rem;
  cursor: pointer;
}

.securityissueCard_dropdown {
  right: 40px;
  top: -4px;
  position: absolute;
  background: #0e0e0e;
  width: 225px;
  padding: 10px 7px;
  border: 1px solid rgb(57, 143, 254);
  cursor: pointer;
}

.securityissueCard_dropdown_unselected {
  padding: 3px 8px;
  margin-bottom: 5px;
}

.securityissueCard_dropdown_selected {
  background: #4a4a4a;
  padding: 3px 8px;
  margin-bottom: 5px;
  border-radius: 1px;
}

.securityissueCard_section_3_value_primary {
  font-size: 24px;
  color: $primary-color;
}

.securityissueCard_section_3_value_secondary {
  font-size: 24px;
  color: $secondary-color;
}

.securityissueCard_section_3_value_tertiary {
  font-size: 24px;
  color: $tertiary-color;
}

.listing_value_primary {
  font-size: 1.2rem;
  color: $primary-color;
}

.listing_value_secondary {
  font-size: 1.2rem;
  color: $secondary-color;
}

.listing_value_tertiary {
  font-size: 1.2rem;
  color: $tertiary-color;
}

// Blank Page cyber services
.cyber-blank-page {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cyber-button {
  max-width: 420px;
}

.new-old {
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 1) !important;
  border-color: white !important;
}

.new-old:hover {
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 1);
  border-color: white;
}

// .modal-backdrop.show {
//   backdrop-filter: blur(7px) !important;
//   opacity: 1 !important;
//   background: rgba(88, 88, 88, 0.68) !important;
// }

.securityissueCard_modal {
  background: #252525;
  border: 1px solid #93c2ff !important;
  width: 649px;
  left: -130px;
}

.securityissueCard_modal_section {
  padding: 15px 20px 20px 40px;
}

.securityissueCard_modal_header {
  padding-bottom: 12px;
  font-size: 24px;
}

.securityissueCard-modal_content {
  color: #e5e5e5;
  font-size: 16px;
  line-height: 22px;
}

.securityissueCard_modal_button {
  padding-top: 30px;
  display: flex;
  justify-content: end;
  gap: 9px;
}

.securityissueCard_modal_button button {
  background: #252525;
  width: 19%;
  color: #398ffe;
  border: 1px solid #398ffe !important;
}

.base-filter-selctbox {
  border-color: #ffffff;
  width: 310px;
  margin-left: 24px;
  color: #a7a7b1;
  background-image: url("../../../images/arrow-select.svg");
  background-position: right 0.5rem center;
  background-size: 21px 23px;
}

.assets_ip_section {
  display: flex;
  flex-wrap: wrap;
  // min-width: 365px;
}

.cisa-kev-tag {
  border-radius: 6px;
  padding: 2px 10px;
  font-size: 12px;
}

.w-mx-content {
  width: max-content;
}

.critical-assets-tag {
  background: #ff6155;
  height: 20px;
  padding: 2px 8px 2px 8px;
  border-radius: 4px;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
}

.issues-assets-tag {
  height: 20px;
  padding: 2px 8px 2px 8px;
  border-radius: 4px;
  background: #ffffff33;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
}

.section-seen-days {
  .title-text {
    font-size: 12px;
    font-weight: 400;
    color: #b1b1b1;
  }

  .data-value-text {
    font-size: 12px;
    font-weight: 400;
    color: #ffffffe5;
  }
}

.issue-status-type {
  .title-text {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }

  .value-text {
    font-size: 12px;
    font-weight: 500;
    color: #a7a7b1;
  }
}

.cve-tag {
  width: 40px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  padding: 2px 8px 2px 8px;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
}

.text-unwrap {
  text-wrap: nowrap;
}

//
.security-highlight-card {
  .text-highlights-title {
    color: #f5f5f5;
  }

  #Highlights-strength-high {
    height: 63px;
    list-style: none;
    overflow: hidden;
    margin-right: 0.7rem;
    margin-bottom: 13px;
    padding-left: 25px;

    li {
      display: inline-block;
      width: 0.37rem;
      height: 100%;
      margin-right: 2px;
    }

    li.strong {
      padding-top: 25px;
      padding-bottom: 12px;
    }

    li.weak {
      padding-top: 32px;
      padding-bottom: 12px;
    }

    li.very-weak {
      padding-top: 39px;
      padding-bottom: 12px;
    }

    li div {
      height: 100%;
      background: #ff6155;
      border-radius: 10px;
    }
  }

  #Highlights-strength-medium {
    height: 63px;
    list-style: none;
    overflow: hidden;
    margin-right: 0.7rem;
    margin-bottom: 13px;
    padding-left: 25px;

    li {
      display: inline-block;
      width: 0.37rem;
      height: 100%;
      margin-right: 2px;
    }

    li.strong {
      padding-top: 25px;
      padding-bottom: 12px;
    }

    li.weak {
      padding-top: 32px;
      padding-bottom: 12px;
    }

    li.very-weak {
      padding-top: 39px;
      padding-bottom: 12px;
    }

    li div {
      height: 100%;
      background: #ff9f45;
      border-radius: 10px;
    }

    li .greyBar {
      height: 100%;
      background: #686868;
      border-radius: 10px;
    }
  }

  #Highlights-strength-low {
    height: 63px;
    list-style: none;
    overflow: hidden;
    margin-right: 0.7rem;
    margin-bottom: 13px;
    padding-left: 25px;

    li {
      display: inline-block;
      width: 0.37rem;
      height: 100%;
      margin-right: 2px;
    }

    li.strong {
      padding-top: 25px;
      padding-bottom: 12px;
    }

    li.weak {
      padding-top: 32px;
      padding-bottom: 12px;
    }

    li.very-weak {
      padding-top: 39px;
      padding-bottom: 12px;
    }

    li div {
      height: 100%;
      background: #07c00e;
      border-radius: 10px;
    }

    li .greyBar {
      height: 100%;
      background: #686868;
      border-radius: 2px;
    }
  }
}

.group-switch {
  .form-check-input {
    height: 20px;
    width: 38px;
    border-bottom: 19px;
  }

  .form-check-label {
    margin-top: 2px;
    margin-left: 5px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
  }

  .form-switch .form-check-input {
    background-color: #7d7d91;
    background-image: url("../../../images/switch-circle.svg");
    background-size: auto;
    background-position: 1px center;
  }

  .form-check-input:checked[type="checkbox"] {
    background-color: #398ffe;
    background-position: 17px center !important;
  }
}

.port-collapse-table {
  .securityissueCard_main_secondary {
    border-top: 1px solid #636363;
    border-bottom: 1px solid #636363;
    border-right: 1px solid #636363;
    border-radius: 5px;
  }

  .securityissueCard_main_tertiary {
    border-top: 1px solid #636363;
    border-bottom: 1px solid #636363;
    border-right: 1px solid #636363;
    border-radius: 5px;
  }

  .securityissueCard_main_primary {
    border-top: 1px solid #636363;
    border-bottom: 1px solid #636363;
    border-right: 1px solid #636363;
    border-radius: 5px;
  }
}

.tab-issue-title {
  font-size: 20px;
  font-weight: 400;
}

.group-tab-sub {
  color: #f5f5f5;
  font-size: 14px;
}

.group-tab-value {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.group-issues {
  .group-categroy-tags {
    display: flex;
    flex-direction: column;
  }

  .issues-assets-tag {
    margin-bottom: 0.5rem !important;
  }

  .tag-m-0 {
    margin: 0 !important;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  /* Adjust the width as needed */
  display: inline-block;
  vertical-align: middle;
}

.dp-selected-item {
  min-width: 210px;
  max-width: 12vw;

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.no-pointer {
  button {
    cursor: default !important;
    pointer-events: none !important;
  }
}

.restore_button {
  background: transparent;
  color: #398ffe;
  border: 1px solid #398ffe !important;
}

.assets-align {
  margin-bottom: 3px;
}

.empty-description {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}
.empty-message-title {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}

.criticality-level {
  margin-top: 12px;
  margin-left: 6px;
  font-size: 12px;
}
.securityissueCard-modal_radio_group {
  .form-check-input:checked {
    --bs-form-check-bg-image: url(../../../images/radio.png) !important;
    background-color: #0d6dfd00 !important;
    background-size: 14px 14px !important;
    // background-position: 4px 3px !important;
    border-width: 2px !important;
  }
  .form-check-input {
    width: 1.5em !important;
    height: 1.5em !important;
    margin-bottom: 10px;
    border: 2px solid #398ffe !important;
    --bs-form-check-bg: rgba(255, 0, 0, 0) !important;
  }
  .form-check {
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: left;
    label {
      padding-left: 6px;
      margin-bottom: 6px;
    }
  }
}
.securityissueCard_subtitlewar {
  color: #3ddcd4;
  font-weight: 700;
  margin-left: 0.5rem;
  font-size: 12px;
}
.securityissueCard_subtitleWarGroup {
  color: #3ddcd4;
}

.expose-genericdata {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  display: inline-block;
}

.text-login-page-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
  vertical-align: middle;
}

.popup-armory-tested-tag {
  margin-top: -10px;
  margin-bottom: 0 !important;
  margin-left: 15px;
}

.high-risk-new-container {
  div {
    font-size: 20px !important;
  }
  svg {
    width: 20px;
  }
}
.is-secuiryt-new-tag {
  background: #ffffff33;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 700;
  color: white;
}
.is-new-tag-grouped {
  display: flex;
  align-items: center;
  margin-left: 8px;
  .comma-icon {
    margin-right: 8px;
    color: white;
    position: relative;
    top: -3px;
  }
}

.total-new-issues-title {
  font-size: 32px;
  text-decoration: underline;
  cursor: pointer;
  @media screen and (max-width: 1440px) and (max-height: 900px) {
    font-size: 24px !important;
  }
}
