.add-asset {
  font-weight: 700;
  font-size: 16px;
  margin-top: 1rem;
  color: #b8b8b8;
}
.create-target-main {
  padding: 0px 24px 24px;
  border-radius: 4px;
  .create-target-container {
    width: 80%;
    margin: auto;
    .create-target-form {
      .default-text {
        display: flex;
        align-items: center;
        margin-top: 50px !important;
      }

      h1 {
        font-weight: 600;
      }

      .sub-heading {
        font-weight: 400;
        font-weight: 16px;
      }

      .tag-default {
        padding: 2px 10px 3px;
        color: white;
        border-radius: 5px;
        background: #398ffe;
        font-size: 11px;
        font-weight: 200;
        position: relative;
        top: -2px;
        left: 10px;
      }

      .checkbox-scan {
        .form-check-input[type="checkbox"] {
          border-radius: 0.25em;
          margin-top: 25px;
          gap: 9px;
        }
      }

      .box {
        background: #373737;
        padding: 10px;
        border-radius: 4px;
        display: flex;
        align-items: start;
        justify-content: center;

        .box-text {
          color: white;
          font-size: 16px;
          font-weight: 400;
          display: contents;
        }
      }

      hr {
        margin-top: 22px !important;
        margin-bottom: -20px !important;
        border: 1px solid #454545;
      }

      label {
        color: #b8b8b8;
        margin-top: 20px;
      }

      .form-control {
        margin: 5px 0px 0px 0px;
      }

      .form-control:not(.is-invalid) {
        border-color: white !important;
      }

      .form-control::placeholder {
        color: #6f6f6f !important;
        font-size: 16px;
      }
    }
  }
}

.nav-text {
  font-size: 14px;
}

.active-nav-text {
  border-bottom: 3px solid #398ffe;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #398ffe !important;
  margin-bottom: -8px;
}

.font-500 {
  font-weight: 500;
}

.example-badge {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px 8px 2px 8px;
  border-radius: 4px;
  font-size: 13px;
}

.file-heading {
  font-weight: 600;
  color: white;
  .link {
    color: #398ffe;
    cursor: pointer;
  }
}
.base-table-container {
  height: 100%;
  table {
    margin: 0;
  }
}

.upload-container {
  margin-top: 8px;
  border: 2px dashed #398ffe;
  border-radius: 8px;
  padding: 13px 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  label {
    margin-top: 0 !important;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .spinner-loader {
    animation: spin 1s linear infinite;
    margin-bottom: 3px;
  }
}

.upload-container:hover {
  background-color: #398ffe36;
}

.upload-container.uploading {
  background-color: #398ffe36;
}

button {
  margin-left: 10px;
}

.button-link {
  text-decoration: underline;
  cursor: pointer;
  color: white;
}
.button-link:hover {
  color: #398ffe;
}

.table-section::-webkit-scrollbar {
  width: 5px;
}
.table-section::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
}

.preview_modal {
  border-radius: 4px;
  padding: 30px;
  background: #252525;
  border: 1px solid #93c2ff !important;
  width: 900px;
  min-width: 300px;
  left: -120px;
  .custom-table > tbody > tr > td {
    background-color: #474749;
  }
}

.preview_modal_button {
  display: flex;
  gap: 9px;
  justify-content: end;
  margin-top: 10px;
}

.preview_modal_button button {
  background: #252525;
  width: 19%;
  color: #398ffe;
  border: 1px solid #398ffe !important;
}

.input-example-container {
  color: rgba(255, 255, 255, 0.7);
  .example-badge {
    color: rgba(255, 255, 255, 0.6);
  }
}
.light-theme-base-tooltip {
  .tooltip-inner {
    background: white;
    color: black;
  }
}