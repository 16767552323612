.globe-easm {
    background: #FFFFFF40;
    height: 133px;
    width: 133px;
    border-radius: 50%;
  }


  .custom-checkbox {
    width: 16px;
    height: 16px;
    background: none;
    border: 2px solid #A7A7B1;
    border-radius: 2px;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
}

.custom-checkbox:checked {
    background-color: #A7A7B1;
}

.custom-checkbox:checked::before {
    content: '✓';
    display: block;
    text-align: center;
    color: white;
    font-size: 12px;
    line-height: 16px;
}

.sticky-action {
    position: sticky;
    right: 0;
    border-left: 2px solid #9F9FA4;
    padding-right: 15px;
}

.issue-checkbox{
    margin-bottom: 13px;
    margin-right: 7px !important;
}
.issue-check-box{
margin-right: 4px;
margin-top: 2rem;
}
.security-checkbox {
    width: 12px;
    height: 12px;
    background: none;
    border: 2px solid #A7A7B1;
    border-radius: 2px;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    

}
.security-checkbox:checked {
    border: none;
}
.security-checkbox:checked::before {
    content: url('../../../images/blue-checkbox.png');
}



.Toastify__toast-body{ 
   margin-top: 0px !important;
   padding-top: 0px !important;
    align-items: start !important;
}
.Toastify__progress-bar {
    top: 0 !important;

}
.Toastify__toast{
    padding-top: 15px !important;
    font-family: "Kodchasan";
    }

    .toast-text{
     font-family: "Kodchasan" !important;
    color: #3B3B3E !important;
    }
    
    .fw-600{
    font-weight: 600;
   
    } .fs-12{
    font-size: 12px;
    }
    .Toastify__toast-theme--light {
     color: #3B3B3E !important;
}