/* -------------------------------------------------------------------------------
### Imports -> Variables -> Colors
------------------------------------------------------------------------------- */
$h1-font-size:                32px;
$h2-font-size:                24px;
$h3-font-size:                18px;
$h4-font-size:                16px;
$h5-font-size:                14px;
$h6-font-size:                12px;


// Fonts
$font-family-sans-serif: "Kodchasan";

// Link
$link-decoration: none;
$link-hover-decoration: none;

// border radius
$border-radius:   0.25rem !important;