.data-processing-screen-main {
    width: 100%;
    height: 66vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .icon{
        cursor: pointer;
    }
}

.link-assets{
    display: flex;
    align-items: center;
    background: #525253;
    max-width: 100%;
    padding: 2px 10px;
    border-radius: 4px;
}