.base-modal {
  .modal-footer {
    border: none;
    padding: 15px;
    .button {
      color: #398ffe;
      border: 1px solid #398ffe;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
    }
  }
  .modal-header {
    border-bottom: none;
    padding: 15px;
  }
  .modal-content {
    background: #252525;
    border: 1px solid #93c2ff;
    padding: 15px;
  }
  .modal-title {
    font-weight: 700;
    size: 24px;
  }
  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    color: var(--white);
    pointer-events: none;
    background-color: var(--disbaled-bg-color);
    border-color: var(--disbaled-bg-color);
    opacity: 1;
  }
}

.cancel-agree {
  cursor: pointer;
  color: #398ffe;
}

.assets-detail-modal {
  .modal-dialog {
    max-width: 1724px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.change-password-modal {
  .modal-dialog {
    max-width: 664px !important;
  }
  .text-link {
    font-size: 16px;
    font-weight: 400;
    line-height: 15.6px;
    color: var(--link-color);
    text-decoration: underline;
  }
}

.change-password-modal {
  .modal-dialog {
    max-width: 664px !important;
  }
  .text-link {
    font-size: 16px;
    font-weight: 400;
    line-height: 15.6px;
    color: var(--link-color);
    text-decoration: underline;
  }
}
.custom-modal-width {
  .modal-dialog {
    max-width: 888px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.Add-User-modal-width {
  .modal-dialog {
    max-width: 974px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.preview-modal-width {
  .modal-dialog {
    max-width: 1400px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.color-white-70 {
  color: #ffffff;
  opacity: 70%;
}
.color-white {
  color: #ffffff;
}

.whats-new-background {
  background: linear-gradient(
    to bottom,
    rgba(57, 143, 254, 1),
    rgba(57, 143, 254, 0)
  );
  padding: 50px 28%;
}

.update-list {
  background: rgb(39, 39, 40);
  // border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 8px 16px;
  justify-content: space-between;
  display: flex;
  align-items: center;

  .justify-between {
    justify-content: space-between;
  }

  .divider {
    background: white;
    width: 2px;
    height: 16px;
    margin-right: 10px;
  }

  .items-center {
    align-items: center;
  }
}

.new-details {
  // background-color: transparent;
  padding: 26px 28px;
  // border: 1px solid #ffffff;
  // border-top: 0px;
  // border-radius: 0px 0px 4px 4px;
  background-color: black;
  color: white;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ul {
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0px;
  }
  .text-link {
    font-size: 14px;
    font-weight: 600;
    line-height: 15.6px;
    color: var(--link-color);
    text-decoration: none;
    cursor: pointer;
    margin-top: 2px;
    align-self: start;
  }
}

.new-badge {
  color: #3e90ff;
  min-width: 45px;
  width: auto;
  height: 25px;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 5px;
  background-color: #d1e5ff;
  box-shadow: none;
}
