.font {
  font-family: 'Kodchasan';
  color: #ffffff;
}

//AssetDiscoveryCard
.card-asset-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background-image: linear-gradient(180deg, #5a5a5b, #5a5a5b84);
  border-radius: 3px;
  flex-wrap: wrap;
  padding: 1.2rem 1rem;
  height: 9rem;

  .card_title {
    color: white;
    font-weight: 700;
    font-size: 1.1rem;
  }

  .card_assets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }

  .card_content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }

  .subcontent1,
  .subcontent2,
  .subcontent4,
  .subcontent5 {
    margin-left: 0.5rem;
  }

  .subcontent2 {
    font-size: 2.7rem;
    font-weight: 700;
    margin-right: 0.5rem;
  }

  .subcontent4 {
    align-items: flex-start;
    flex-direction: column;
  }

  .subtosubcontent1,
  .subtosubcontent2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) translateX(15px);
  }

  100% {
    transform: rotate(360deg) translateX(15px);
  }
}

.topissueCard_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #120B15;
  width: 100%;
  height: 80%;
}

.loader_circular {
  position: relative;
  width: 100px;
  height: 50px;

  .bar {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 3px;
    background: #ffffff;
    border-radius: 2px;
    animation: rotate 2s linear infinite;
  }

  .bar:nth-child(1) {
    animation-delay: 0s;
  }

  .bar:nth-child(2) {
    animation-delay: 0.25s;
  }

  .bar:nth-child(3) {
    animation-delay: 0.5s;
  }

  .bar:nth-child(4) {
    animation-delay: 0.75s;
  }

  .bar:nth-child(5) {
    animation-delay: 1s;
  }

  .bar:nth-child(6) {
    animation-delay: 1.25s;
  }

  .bar:nth-child(7) {
    animation-delay: 1.5s;
  }

  .bar:nth-child(8) {
    animation-delay: 1.75s;
  }
}

//RiskScoreCard
.riskscorecard_dataLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.risk-score-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(180deg, #5a5a5b, #5a5a5b84);
  border-radius: 3px;
  padding: 0.4rem 1rem 1rem 1rem;
  height: 11rem;
  width: 39%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  .risk-score-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;

    h2 {
      font-size: 1.1rem;
      font-weight: 700;
      margin: 0;
    }

    h3,
    .current-risk-score {
      font-weight: 700;
      font-size: 1rem;
      margin: 0;
    }

    .current-score {
      display: flex;
      align-items: center;
      justify-content: center;


      .current-score-value {
        color: #3DDC97;
        font-size: 2rem;
        font-weight: 700;
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .risk-score-chart {
    flex-grow: 1;
    width: 100%;
    height: 4rem;
  }
}

//OffensiveTestingCard
.offensivetesting_dataLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  background-image: linear-gradient(180deg, #5a5a5b, #5a5a5b84);
  border-radius: 3px;
  flex-wrap: wrap;
  padding: 1rem;
  height: 11rem;
  width: 28%;

  .card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .card_title {
    color: white;
    font-weight: 700;
    font-size: 1.1rem;
  }

  .card_content_main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .card_content_style {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
  }

  .content_values_style,
  .content_text_style {
    display: flex;
    align-items: center;
    justify-content: center;

    &.content_values_style {
      color: #ffffff; // Default text color
    }

    &.content_text_style {
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .content_value {
    font-weight: 700;
    font-size: 2rem;
    margin-right: 0.2rem;
  }
}

//TopIssueCard
.font {
  font-family: 'Kodchasan';
  color: #ffffff;
}

.card-3 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  background-image: linear-gradient(180deg, #5a5a5b, #5a5a5b84);
  border-radius: 3px;
  flex-wrap: wrap;
  padding: 1rem;
  height: 11rem;
  width: 28%;

  .card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .margindiv {
    background-color: #130C15;
    width: 100%;
    padding: 0.3rem 0.3rem;
    border-top: 1px solid #636363;
    ;
  }

  .card_title {
    color: white;
    font-weight: 700;
    font-size: 1.1rem;
  }

  .card_subsection {
    background-color: #120B15;
    width: 100%;
    height: 80%;
    max-height: 7rem;
    overflow-y: scroll;



    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-track {
      background: #120B15;
    }

    &::-webkit-scrollbar-thumb {
      background: #ffffff;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #FFFFFF;
    }

    ul {
      li {
        &.active {
          img {
            transform: none; // Reset transformation when active
          }
        }

        &.issue-item {
          color: #3DDC97; // Change the color of the text

          span {
            color: rgb(255, 255, 255); // Reset the color for the span text
          }

          .additional-text {
            color: white;
          }
        }

        img {
          transform: scaleY(-1);
        }
      }
    }
  }
}

//RiskCard

.loader {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 35px;
  width: 70%;
  margin-bottom: 1rem;
}

.loader-bar {
  width: 70px;
  height: 35px;
  margin: 0 0;
  background-color: transparent;
  border: 2px solid grey;
  box-sizing: border-box;
  animation: fillBar 5.5s infinite;
}

@keyframes fillBar {

  0%,
  100% {
    height: 0%;
    background-color: transparent;
  }

  2%,
  90% {
    height: 100%;
    background-color: #BCF4DE;
  }
}

// Animate bars one after another
@for $i from 1 through 15 {
  .loader-bar:nth-child(#{$i}) {
    animation-delay: (0.31s * ($i - 1));
  }
}


.radar-loader {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background-image: conic-gradient(#0d99ff 0%, rgba(13, 153, 255, 0) 40%);
  animation: spin 2s linear infinite, pulsate 1.5s ease-out infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 0 rgba(13, 153, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(13, 153, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(13, 153, 255, 0);
  }
}


.riskcard_main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('../../../images/RiskCard_bg.png');
  background-size: cover;
  border-radius: 3px;
  flex-wrap: wrap;
  height: 37rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.5rem;

  .section_1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    width: 25%;

    .subsection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      background-color: #141416;
      border: 1px solid #D5D8DC;
      border-radius: 3px;
      height: 11.188rem;
      width: 18.125rem;
    }

    .risksubcard_heading {
      background-color: #272728;
      border-bottom: 1px solid #636363;
      border-radius: 3px;
      width: 100%;
      height: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
    }

    .risksubcard_update {
      width: 90%;
      height: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .issue_text {
        color: #FF6155;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    .risksubcard_nextupdate {
      width: 90%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .section_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../../images/RiskRadar.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 3px;
    height: 80%;
    width: 35%;
    z-index: 1;



    .content_1 {
      display: flex;
      align-items: center;
      justify-content: center;

      .content_1_value {
        color: #64FFFF;
        font-weight: 700;
        font-size: 2rem;
        margin-right: 0.5rem;
      }
    }

    .content_2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

    }
  }

  .section_3 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    height: 100%;
    width: 25%;
    z-index: 1;

    .subsection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      background-color: #141416;
      border: 1px solid #D5D8DC;
      border-radius: 3px;
      height: 11.188rem;
      width: 18.125rem;
    }

    .risksubcard_heading {
      background-color: #272728;
      border-bottom: 1px solid #636363;
      border-radius: 3px;
      width: 100%;
      height: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
    }

    .risksubcard_update {
      width: 90%;
      height: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .issue_text {
        color: #FF6155;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    .risksubcard_nextupdate {
      width: 90%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .pointer_left {
    position: absolute;
    left: 20.25rem;
    top: 8.438rem;

    img {
      width: 10rem;
      height: 21.875rem;
    }
  }

  .pointer_right {
    position: absolute;
    right: 20.25rem;
    top: 110px;

    img {
      width: 9.7rem;
      height: 21.875rem;
      transform: rotate(180deg);
    }
  }

  .gradient {
    position: absolute;
    z-index: 0;
    background: linear-gradient(90deg, #0D99FF 0%, #0D99FF 100%);
    filter: blur(100px);
    height: 350px;
    width: 350px;
    border-radius: 50%; // If you want to make it round like a circle
    animation: glow 1s infinite alternate; // Apply the animation to the gradient
  }

  @keyframes glow {
    0% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }

}

.blur {
  filter: blur(4px);
}

.particle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #0D99FF;
  border-radius: 50%;
  .part1 {
    top: 40%;
    left: 60%;
  }
  .part2 {
    top: 40%;
    left: 80%;
  }
  .part3 {
    top: 63%;
    left: 70%;
  }
}


// ai button
.notification-bar {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 70px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1E1E20;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.notification-bar p {
  margin: 0;
  color: #fff;
  font-size: 1rem;
  flex: none;
}

.notification-message {
  background-color: #0F0F10;
  padding: 0 1rem;
  margin-left: 1rem;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  color: #3DDC97;
  flex-grow: 1;
  overflow: hidden;
}

.notification-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 1rem;
}

.notification-close-btn:hover {
  opacity: 0.7;
}

.svg-icon {
  fill: #fff;
  cursor: pointer;
  margin: 0 0.5rem;
}

.dot {
  color: #ffffff;
  font-size: 2rem;
  margin-right: 0.2rem;
}